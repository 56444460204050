import React, { useContext, useEffect } from 'react';

import { GramUnits, InjectionScreen, UnitType } from '../../../types';

import CalculatorComponent from '../../../components/CalculatorComponent';

import { InjectionContext } from '../../../contexts/InjectionProvider';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { CalculatorContext } from '../../../contexts/CalculatorProvider';

function InjectionSecondScreen(): React.ReactElement {
 const { setBackButtonText, setNextButtonAction, setBackButtonAction } =
  useContext(CalculatorNavigationContext);

 const {
  setCurrentScreen,
  onValueChange,
  onSelectChange,
  getSelectInitialState,
  getValueInitialState,
 } = useContext(InjectionContext);

 const { setHasCheckbox, setQuestion, setUnitTypes } =
  useContext(CalculatorContext);

 const unitTypes = GramUnits.filter(unit =>
  [UnitType.Gram, UnitType.Kilogram].includes(unit.description),
 );

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(() => () => setCurrentScreen(InjectionScreen.First));
  };
  const setNextButtonProps = () => {
   setNextButtonAction(() => () => setCurrentScreen(InjectionScreen.Third));
  };
  setBackButtonProps();
  setNextButtonProps();
 };

 const setCalculatorProps = () => {
  setHasCheckbox(false);
  setQuestion('Hvor mye veier pasienten?');
  setUnitTypes(unitTypes);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <CalculatorComponent
   onValueChange={onValueChange}
   onSelectChange={onSelectChange}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default InjectionSecondScreen;
