import React, { useContext, useEffect } from 'react';
import { VStack } from '@chakra-ui/react';
import { AppRoutes, ListElement } from '../../types';
import ListComponent from '../../components/ListComponent';
import {
 capitalizeFirstLetter,
 removeFirstLetter,
 getIcons,
} from '../../utils';
import FooterComponent from '../../components/FooterComponent';
import { ResultsContext } from '../../contexts/ResultsProvider';

function HomePage(): React.ReactElement {
 const icons = getIcons();

 const { setResultOperations, setResultRows } = useContext(ResultsContext);

 const elements = Object.values(AppRoutes)
  .filter(path => ![AppRoutes.Home].includes(path))
  .map(path => {
   const pathRemovedFirstLetter = removeFirstLetter(path);
   let title = capitalizeFirstLetter(pathRemovedFirstLetter);
   const icon = icons[path];
   if (path === AppRoutes.Pills) {
    title += ' o. I.';
   }
   const element: ListElement = { icon, path, title };
   return element;
  });

 useEffect(() => {
  setResultOperations([]);
  setResultRows([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <VStack w="100%">
   <ListComponent elements={elements} />
   <FooterComponent />
  </VStack>
 );
}

export default HomePage;
