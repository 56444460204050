import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
 AppRoutes,
 CalculationHistory,
 LiquidScreen,
 MathOperation,
 ResultOperation,
 ResultRow,
 UnitType,
} from '../types';

import { LiquidContextType } from '../types/contexts';

import {
 getUnitTypeByString,
 getUnitOfMeasurementByUnitType,
 roundNumber,
 getValuesFromResultRows,
 getDescriptionsFromResultRows,
 convertUnit,
 getUnitsFromResultRows,
 getValuesPerWeightFromResultRows,
} from '../utils';

import { CalculatorContext } from './CalculatorProvider';

import { ResultsContext } from './ResultsProvider';

export const LiquidContext = createContext<LiquidContextType>({
 setCurrentScreen: () => null,
 setLiquidResultRows: () => null,
 liquidResultRows: [],
 onSelectChange: () => null,
 onValueChange: () => null,
 getValueInitialState: () => '',
 getSelectInitialState: () => '',
 selectedUnit: UnitType.Gram,
 setSelectedUnit: () => null,
 loadHistory: () => null,
 setCalculationHistory: () => null,
});

interface LiquidProviderProps {
 children: React.ReactElement;
}

export function LiquidProvider({
 children,
}: LiquidProviderProps): React.ReactElement {
 const { checkboxValue, setCheckboxValue } = useContext(CalculatorContext);

 const navigation = useNavigate();

 const [currentScreen, setCurrentScreen] = useState(-1);

 const [liquidResultRows, setLiquidResultRows] = useState<Array<ResultRow>>([
  { description: 'Dose' },
  { description: 'Pasientens vekt' },
  { description: 'Styrke pr ml' },
  { description: 'Dråper pr ml' },
 ]);
 const [liquidOperationsResult, setLiquidOperationsResult] = useState<
  Array<ResultOperation>
 >([
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
 ]);
 const [liquidFinalResult, setLiquidFinalResult] = useState(0);
 const [selectedUnit, setSelectedUnit] = useState<string>('');

 const {
  setResultRows,
  setFinalResultValue,
  setResultOperations,
  setResultsCalculationHistory,
 } = useContext(ResultsContext);

 const [calculationHistory, setCalculationHistory] =
  useState<CalculationHistory>({
   title: 'Flytende - Dråper',
   redirectTo: AppRoutes.Liquid,
  });

 const saveHistory = () => {
  setCalculationHistory({
   ...calculationHistory,
   date: new Date(),
   data: {
    currentScreen: LiquidScreen.Result,
    resultRows: liquidResultRows,
    operationResult: liquidOperationsResult,
    checkboxValue,
   },
  });
 };

 const loadHistory = (data: any) => {
  if (data.currentScreen) {
   setCurrentScreen(data.currentScreen);
  }
  if (data.resultRows) {
   setLiquidResultRows(data.resultRows);
  }
  if (data.operationResult) {
   setLiquidOperationsResult(data.operationResult);
  }
  if (data.checkboxValue) {
   setCheckboxValue(data.checkboxValue);
  }
  if (data.index !== undefined) {
   setCalculationHistory({
    ...calculationHistory,
    index: data.index,
   });
  }
 };

 const homeRedirect = () => () => {
  setCalculationHistory({ ...calculationHistory, index: undefined });
  setLiquidResultRows([
   { description: 'Dose' },
   { description: 'Pasientens vekt' },
   { description: 'Styrke pr ml' },
   { description: 'Dråper pr ml' },
  ]);
  setCurrentScreen(-1);
  navigation(AppRoutes.Home);
 };

 const firstScreenRedirect = () => () => setCurrentScreen(LiquidScreen.First);
 const secondScreenRedirect = () => () => setCurrentScreen(LiquidScreen.Second);
 const thirdScreenRedirect = () => () => setCurrentScreen(LiquidScreen.Third);
 const fourthScreenRedirect = () => () => setCurrentScreen(LiquidScreen.Fourth);
 const resultsRedirect = () => () => setCurrentScreen(LiquidScreen.Result);

 const onSelectChange = (string: string) => {
  const unitType = getUnitTypeByString(string);
  const unitOfMeasurement = getUnitOfMeasurementByUnitType(unitType);
  setLiquidResultRows(
   liquidResultRows?.map((input, index) =>
    index === currentScreen
     ? {
        ...input,
        calculatorValue: { ...input.calculatorValue, unit: unitOfMeasurement },
       }
     : input,
   ),
  );
 };

 const onValueChange = (value: string) => {
  setLiquidResultRows(
   liquidResultRows?.map((input, index) =>
    index === currentScreen
     ? { ...input, calculatorValue: { ...input.calculatorValue, value } }
     : input,
   ),
  );
 };

 const getValueInitialState = () => {
  const value = liquidResultRows.find((input, index) => index === currentScreen)
   ?.calculatorValue?.value;
  return value ?? '';
 };

 const getSelectInitialState = () => {
  const value = liquidResultRows.find((input, index) => index === currentScreen)
   ?.calculatorValue?.unit?.description;
  return value ?? '';
 };

 const calculateResult = () => {
  const values = getValuesPerWeightFromResultRows(liquidResultRows);
  const newOperationsResult = [...liquidOperationsResult];
  if (
   !values[LiquidScreen.First] ||
   !values[LiquidScreen.Third] ||
   !values[LiquidScreen.Fourth]
  ) {
   return;
  }
  let partialResult = values[LiquidScreen.First];
  if (values[LiquidScreen.Second]) {
   partialResult =
    (values[LiquidScreen.First] * values[LiquidScreen.Second]) / 1000;
  }
  partialResult /= values[LiquidScreen.Third];
  newOperationsResult[1].resultValue = `${roundNumber(partialResult)} ${
   UnitType.Ml
  }`;
  newOperationsResult[2].firstValue = `${roundNumber(partialResult)} ${
   UnitType.Ml
  }`;
  partialResult *= values[LiquidScreen.Fourth];
  const result = roundNumber(partialResult);
  setLiquidFinalResult(result);
  newOperationsResult[2].resultValue = `${result} ${UnitType.Draper}`;
  setLiquidOperationsResult(newOperationsResult);
 };

 const calculateOperations = () => {
  const description = getDescriptionsFromResultRows(liquidResultRows);
  const values = getValuesFromResultRows(liquidResultRows);
  const units = getUnitsFromResultRows(liquidResultRows);
  const newOperationsResult = [...liquidOperationsResult];
  if (description[LiquidScreen.First]) {
   newOperationsResult[0].firstValue = `${description[LiquidScreen.First]}/kg`;
  }
  if (description[LiquidScreen.Second]) {
   newOperationsResult[0].secondValue = description[LiquidScreen.Second];
   const resultValue = convertUnit(
    values[LiquidScreen.First] * values[LiquidScreen.Second],
    units[LiquidScreen.First],
    units[LiquidScreen.Second],
   );
   newOperationsResult[0].resultValue = resultValue;
   newOperationsResult[1].firstValue = resultValue;
  } else {
   newOperationsResult[1].firstValue = description[LiquidScreen.First];
  }
  if (description[LiquidScreen.Third]) {
   newOperationsResult[1].secondValue = description[LiquidScreen.Third];
  }
  if (description[LiquidScreen.Fourth]) {
   newOperationsResult[2].secondValue = `${
    description[LiquidScreen.Fourth]
   }/ml`;
  }
 };

 useEffect(() => {
  setFinalResultValue(liquidFinalResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [liquidFinalResult]);

 useEffect(() => {
  calculateResult();
  calculateOperations();
  setResultRows(liquidResultRows);
  saveHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [liquidResultRows]);

 useEffect(() => {
  setResultOperations(liquidOperationsResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [liquidOperationsResult]);

 useEffect(() => {
  setLiquidResultRows(
   liquidResultRows?.map((input, index) =>
    index > currentScreen ? { ...input, calculatorValue: undefined } : input,
   ),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [currentScreen]);

 useEffect(() => {
  setResultsCalculationHistory(calculationHistory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [calculationHistory]);

 return (
  <LiquidContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    currentScreen,
    setCurrentScreen,
    homeRedirect,
    firstScreenRedirect,
    secondScreenRedirect,
    thirdScreenRedirect,
    fourthScreenRedirect,
    resultsRedirect,
    liquidResultRows,
    setLiquidResultRows,
    onSelectChange,
    onValueChange,
    getValueInitialState,
    getSelectInitialState,
    selectedUnit,
    setSelectedUnit,
    calculationHistory,
    setCalculationHistory,
    loadHistory,
   }}
  >
   {children}
  </LiquidContext.Provider>
 );
}
