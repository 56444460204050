import React, { useContext } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';

import { AiOutlineInfoCircle } from 'react-icons/ai';

import logo from '../../assets/images/HBIB-logo.svg';

import { AppContext } from '../../contexts/AppProvider';
import { CalculatorContext } from '../../contexts/CalculatorProvider';

function HeaderComponent(): React.ReactElement | null {
 const { setShowInfoAlert } = useContext(AppContext);
 const { setIsTimer } = useContext(CalculatorContext);

 const onHandleClickInfo = () => {
  setShowInfoAlert(true);
  setIsTimer(false);
 };

 return (
  <Flex
   alignContent="center"
   justifyContent="center"
   w="100%"
   // py="50px"
   // px="30px"
  >
   <Image src={logo} w="90%" maxWidth="350px" />
   <Box alignSelf="center" ml="10px">
    <AiOutlineInfoCircle size={28} onClick={onHandleClickInfo} />
   </Box>
  </Flex>
 );
}

export default HeaderComponent;
