import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
 fonts: {
  heading: 'Source Sans Pro',
  body: 'Source Sans Pro',
 },
 colors: {
  gray: {
   400: '#e9e9e9',
   500: '#D9D9D9',
   600: '#CFD0CB',
  },
  black: {
   500: '#4C4C4C',
  },
  red: {
   500: '#D14641',
  },
  blue: {
   100: '#DBE6EC',
   900: '#386E84',
  },
  pink: {
   500: '#E8036F',
  },
 },
 styles: {
  global: {
   body: {
    bg: 'white',
    color: 'black.500',
   },
  },
 },
});
