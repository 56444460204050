import React, { useContext } from 'react';

import { Input, VStack, Text, HStack } from '@chakra-ui/react';

import { CalculatorContext } from '../../../contexts/CalculatorProvider';
import { InputTimer } from '../../../types';

interface CalculatorInputsComponentProps {
 onHandleChangeInputValue: (value: Array<string>) => void;
 inputValue: Array<string>;
}

function CalculatorInputsComponent({
 onHandleChangeInputValue,
 inputValue,
}: CalculatorInputsComponentProps): React.ReactElement {
 const { setTimerInputSelected } = useContext(CalculatorContext);

 const onHandleChangeLocal = (
  event: React.ChangeEvent<HTMLInputElement>,
  position: number,
 ) => {
  onHandleChangeInputValue(
   inputValue.map((value, index) =>
    index === position ? event.target.value : value,
   ),
  );
 };

 return (
  <HStack px="30px" width="100%" flex={1}>
   <VStack w="50%">
    <Input
     bg="gray.500"
     type="text"
     h="68px"
     borderRadius={15}
     mr="10px"
     fontSize="42px"
     color="black.500"
     fontWeight="700"
     textAlign="right"
     placeholder="0"
     value={inputValue[0]}
     _placeholder={{ color: '#4C4C4C' }}
     onChange={event => onHandleChangeLocal(event, 0)}
     onFocus={() => setTimerInputSelected(InputTimer.Timer)}
    />
    <Text color="black.500" fontSize="24px">
     timer
    </Text>
   </VStack>
   <VStack w="50%">
    <Input
     bg="gray.500"
     type="text"
     h="68px"
     borderRadius={15}
     mr="10px"
     fontSize="42px"
     color="black.500"
     fontWeight="700"
     textAlign="right"
     placeholder="0"
     value={inputValue[1]}
     _placeholder={{ color: '#4C4C4C' }}
     onChange={event => onHandleChangeLocal(event, 1)}
     onFocus={() => setTimerInputSelected(InputTimer.Minutter)}
    />
    <Text color="black.500" fontSize="24px">
     minutter
    </Text>
   </VStack>
  </HStack>
 );
}

export default CalculatorInputsComponent;
