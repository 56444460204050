import React, { useContext, useState, useEffect } from 'react'
import {
  VStack,
  Text,
  Flex,
  Button,
  Checkbox,
  Box,
  Spinner,
  Center,
} from '@chakra-ui/react'

import { BooleanType } from '../../types'

import { AppContext } from '../../contexts/AppProvider'
// import { GET_APP_INFO_ENDPOINT } from '../../constants';
import Header from '../HeaderComponent'

import './style.css'

function InfoComponent(): React.ReactElement {
  const { setShowInfoAlert, infoContent } = useContext(AppContext)
  const [checkboxValue, setCheckboxValue] = useState(false)
  //  const [content, setContent] = useState('');

  const onHandleClickButton = () => {
    setShowInfoAlert(false)
    if (checkboxValue) {
      localStorage.setItem('showInfoAlert', BooleanType.false)
    } else {
      localStorage.setItem('showInfoAlert', BooleanType.true)
    }
  }

  const onHandleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCheckboxValue(event.target.checked)
  }

  //  useEffect(() => {
  //   if (GET_APP_INFO_ENDPOINT) {
  //    fetch(GET_APP_INFO_ENDPOINT, {
  //     method: 'GET',
  //     headers: {
  //      'Content-Type': 'application/json',
  //     },
  //    })
  //     .then(response => response.json())
  //     .then(data => setContent(data.description))
  //     .catch(() => {
  //      setContent(' ');
  //     });
  //   }
  //  }, []);

  return (
    <VStack px="30px" w="100%" pb="30px" flex={1} height="auto">
      <Flex w="100%" py="30px" px="0px">
        <Header />
      </Flex>
      {infoContent ? (
        <Box
          w="100%"
          className="HTMLArea"
          dangerouslySetInnerHTML={{ __html: infoContent }}
        />
      ) : (
        <Center w="100%" h="100%">
          <Spinner size="xl" speed="1s" />
        </Center>
      )}
      <VStack
        my="30px"
        w="100%"
        mt="auto"
        flex={1}
        justifyContent="flex-end"
        mb="30px"
      >
        <Flex py="30px">
          <Checkbox
            size="lg"
            checked={checkboxValue}
            colorScheme="blue"
            iconColor="white"
            iconSize="20px"
            id="checkbox"
            onChange={onHandleChangeCheckbox}
          >
            <Text color="black.500" fontSize="16px">
              Ikke vis denne teksten ved oppstart
            </Text>
          </Checkbox>
        </Flex>
        <Button
          color="white"
          bg="red.500"
          mt="auto"
          w="100%"
          h="68px"
          fontSize="24px"
          onClick={onHandleClickButton}
        >
          OK
        </Button>
      </VStack>
    </VStack>
  )
}

export default InfoComponent
