import LiquidHomePage from './LiquidHomePage';
import LiquidDraperPage from './LiquidDraperPage';
import LiquidMiksturPage from './LiquidMiksturPage';

const LiquidPage = {
 Home: LiquidHomePage,
 Draper: LiquidDraperPage,
 Mikstur: LiquidMiksturPage,
};

export default LiquidPage;
