// Infusion - Utel tilsetning

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
 AppRoutes,
 CalculationHistory,
 CalculatorOption,
 InfusionRoutes,
 InfusionScreen,
 MathOperation,
 ResultOperation,
 ResultRow,
 UnitType,
} from '../../../types';

import { InfusionAddedDrugContextType } from '../../../types/contexts';

import {
 getUnitTypeByString,
 getUnitOfMeasurementByUnitType,
 roundNumber,
 getDescriptionsFromResultRows,
 getOnlyNumbers,
 getTimerValueFromString,
 getValuesFromResultRows,
 getUnitsFromResultRows,
 getValuesPerWeightFromResultRows,
 formatTimerString,
} from '../../../utils';
import { CalculatorContext } from '../../CalculatorProvider';

import { ResultsContext } from '../../ResultsProvider';

export const InfusionAddedDrugContext =
 createContext<InfusionAddedDrugContextType>({
  setCurrentScreen: () => null,
  setInfusionAddedDrugResultRows: () => null,
  infusionAddedDrugResultRows: [],
  onSelectChange: () => null,
  onValueChange: () => null,
  onValueTimerChange: () => null,
  getValueInitialState: () => '',
  getValueTimerInitialState: () => ['', ''],
  getSelectInitialState: () => '',
  selectedUnit: UnitType.Gram,
  setSelectedUnit: () => null,
  hasFourthScreen: () => false,
  loadHistory: () => null,
  setCalculationHistory: () => null,
  setInfusionAddedDrugInfoText: () => null,
 });

interface InfusionAddedDrugProviderProps {
 children: React.ReactElement;
}

export function InfusionAddedDrugProvider({
 children,
}: InfusionAddedDrugProviderProps): React.ReactElement {
 const { setCheckboxValue, checkboxValue } = useContext(CalculatorContext);

 const navigation = useNavigate();

 const [currentScreen, setCurrentScreen] = useState(-1);

 const [infusionAddedDrugInfoText, setInfusionAddedDrugInfoText] = useState('');

 const [infusionAddedDrugResultRows, setInfusionAddedDrugResultRows] = useState<
  Array<ResultRow>
 >([
  { description: 'Dose' },
  { description: 'Pasientens vekt' },
  { description: '' },
  { description: 'Styrke pr ml' },
  { description: 'Posens totalvolum' },
  { description: 'Varighet' },
 ]);

 const [
  infusionAddedDrugOperationsResult,
  setInfusionAddedDrugOperationsResult,
 ] = useState<Array<ResultOperation>>([
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
 ]);

 const [infusionAddedDrugFinalResult, setInfusionAddedDrugFinalResult] =
  useState<Array<ResultRow>>([
   { description: 'Mengde' },
   { description: 'Hastighet' },
   { description: 'Hastighet' },
   { description: 'Hastighet' },
   { description: 'Hastighet' },
   { description: 'Hastighet' },
  ]);

 const [selectedUnit, setSelectedUnit] = useState<string>('');

 const {
  setResultRows,
  setResultArray,
  setResultOperations,
  setResultsCalculationHistory,
 } = useContext(ResultsContext);

 const [calculationHistory, setCalculationHistory] =
  useState<CalculationHistory>({
   title: 'Infusion - Dev av enhet',
   redirectTo: InfusionRoutes.AddedDrug,
  });

 const saveHistory = () => {
  setCalculationHistory({
   ...calculationHistory,
   date: new Date(),
   data: {
    currentScreen: InfusionScreen.AddedDrug.Result,
    resultRows: infusionAddedDrugResultRows,
    operationResult: infusionAddedDrugOperationsResult,
    checkboxValue,
   },
  });
 };

 const loadHistory = (data: any) => {
  if (data.currentScreen) {
   setCurrentScreen(data.currentScreen);
  }
  if (data.resultRows) {
   setInfusionAddedDrugResultRows(data.resultRows);
  }
  if (data.operationResult) {
   setInfusionAddedDrugOperationsResult(data.operationResult);
  }
  if (data.checkboxValue) {
   setCheckboxValue(data.checkboxValue);
  }
  if (data.index !== undefined) {
   setCalculationHistory({
    ...calculationHistory,
    index: data.index,
   });
  }
 };

 const homeRedirect = () => () => {
  setCalculationHistory({ ...calculationHistory, index: undefined });
  setInfusionAddedDrugResultRows([
   { description: 'Dose' },
   { description: 'Pasientens vekt' },
   { description: '' },
   { description: 'Styrke pr ml' },
   { description: 'Posens totalvolum' },
   { description: 'Varighet' },
  ]);
  setCurrentScreen(-1);
  navigation(AppRoutes.Home);
 };

 const firstScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.AddedDrug.First);
 const secondScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.AddedDrug.Second);
 const thirdScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.AddedDrug.Third);
 const fourthScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.AddedDrug.Fourth);
 const fifthScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.AddedDrug.Fifth);
 const sixthScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.AddedDrug.Sixth);
 const resultsRedirect = () => () =>
  setCurrentScreen(InfusionScreen.AddedDrug.Result);

 const hasFourthScreen = (): boolean => {
  if (
   infusionAddedDrugResultRows[InfusionScreen.AddedDrug.Fourth] &&
   infusionAddedDrugResultRows[InfusionScreen.AddedDrug.Fourth]
    .calculatorValue &&
   infusionAddedDrugResultRows[InfusionScreen.AddedDrug.Fourth].calculatorValue
    ?.value
  ) {
   return true;
  }
  return false;
 };

 const onSelectChange = (string: string) => {
  const unitType = getUnitTypeByString(string);
  const unitOfMeasurement = getUnitOfMeasurementByUnitType(unitType);
  const newinfusionAddedDrugResultRows = [...infusionAddedDrugResultRows].map(
   (input, index) =>
    index === currentScreen
     ? {
        ...input,
        calculatorValue: { ...input.calculatorValue, unit: unitOfMeasurement },
       }
     : input,
  );
  setInfusionAddedDrugResultRows(newinfusionAddedDrugResultRows);
 };

 const onValueChange = (value: string) => {
  const newinfusionAddedDrugResultRows = [...infusionAddedDrugResultRows].map(
   (input, index) =>
    index === currentScreen
     ? { ...input, calculatorValue: { ...input.calculatorValue, value } }
     : input,
  );
  setInfusionAddedDrugResultRows(newinfusionAddedDrugResultRows);
 };

 const onValueTimerChange = (value: Array<string>) => {
  const newinfusionAddedDrugResultRows = [...infusionAddedDrugResultRows].map(
   (input, index) =>
    index === InfusionScreen.AddedDrug.Sixth
     ? {
        ...input,
        calculatorValue: {
         ...input.calculatorValue,
         value: `${value[0].trim() === '' ? `0${value[0]}` : `${value[0]}`}
         ${UnitType.Timer}
         ${value[1] ? `, ${value[1]}${UnitType.Minutes}` : ''}`,
        },
       }
     : input,
  );
  setInfusionAddedDrugResultRows(newinfusionAddedDrugResultRows);
 };

 const getValueInitialState = () => {
  const value = infusionAddedDrugResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.value;
  return value ?? '';
 };

 const getValueTimerInitialState = () => {
  const value =
   infusionAddedDrugResultRows[InfusionScreen.AddedDrug.Sixth].calculatorValue
    ?.value;
  if (!value) {
   return ['', ''];
  }
  return value.split(CalculatorOption.Comma).map(v => getOnlyNumbers(v));
 };

 const getSelectInitialState = () => {
  const value = infusionAddedDrugResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.unit?.description;
  return value ?? '';
 };

 const calculateResult = () => {
  const values = getValuesFromResultRows(infusionAddedDrugResultRows);
  const description = getDescriptionsFromResultRows(
   infusionAddedDrugResultRows,
  );
  const units = getUnitsFromResultRows(infusionAddedDrugResultRows);

  if (
   !description[InfusionScreen.AddedDrug.First] ||
   !description[InfusionScreen.AddedDrug.Fifth] ||
   !description[InfusionScreen.AddedDrug.Sixth]
  ) {
   return;
  }

  let gValueFormatted;
  let kgValue;
  let kgValueFormatted;
  let kgHided = true;
  let conversionHided = true;
  let conversionFirstValue;
  let conversionSecondValue;
  let conversionResult;

  let firstRowFirstValue = values[InfusionScreen.AddedDrug.First];
  if (description[InfusionScreen.AddedDrug.Second]) {
   conversionHided = false;
   kgValue = values[InfusionScreen.AddedDrug.Second];
   if (units[InfusionScreen.AddedDrug.Second]?.description === UnitType.Gram) {
    gValueFormatted = `${kgValue} ${UnitType.Gram}`;
    kgValue /= 1000;
    kgHided = false;
   }
   kgValueFormatted = `${kgValue} ${UnitType.Kilogram}`;
   conversionFirstValue = description[InfusionScreen.AddedDrug.First];

   conversionSecondValue = `${kgValue} ${UnitType.Kilogram}`;
   conversionResult = `${values[InfusionScreen.AddedDrug.First] * kgValue} ${
    units[InfusionScreen.AddedDrug.First]?.description
   }`;
   firstRowFirstValue *= kgValue;
  } else {
   conversionFirstValue = description[InfusionScreen.AddedDrug.First];
  }
  const firstRowFirstValueFormatted = `${firstRowFirstValue} ${
   units[InfusionScreen.AddedDrug.First]?.description
  }`;

  const firstRowSecondValue = getTimerValueFromString(
   description[InfusionScreen.AddedDrug.Sixth],
  );
  let firstRowSecondValueFormatted =
   description[InfusionScreen.AddedDrug.Sixth];
  firstRowSecondValueFormatted =
   firstRowSecondValueFormatted[0] === 't'
    ? `0${firstRowSecondValueFormatted}`
    : firstRowSecondValueFormatted;
  const firstRowResultValue = firstRowFirstValue / firstRowSecondValue;
  const firstRowResultValueFormatted = `${roundNumber(firstRowResultValue)} ${
   units[InfusionScreen.AddedDrug.First]?.description.split('/')[0]
  }/${UnitType.Minutes}`;

  const secondRowFirstValue = values[InfusionScreen.AddedDrug.Fifth];
  const secondRowFirstValueFormatted =
   description[InfusionScreen.AddedDrug.Fifth];
  const secondRowSecondValue = getTimerValueFromString(
   description[InfusionScreen.AddedDrug.Sixth],
  );
  let secondRowSecondValueFormatted =
   description[InfusionScreen.AddedDrug.Sixth];
  secondRowSecondValueFormatted =
   secondRowSecondValueFormatted[0] === 't'
    ? `0${secondRowSecondValueFormatted}`
    : secondRowSecondValueFormatted;
  const secondRowResultValue = secondRowFirstValue / secondRowSecondValue;
  const secondRowResultValueFormatted = `${roundNumber(secondRowResultValue)} ${
   UnitType.MlPerMinute
  }`;

  const minutesValueFormatted = `${secondRowSecondValue} ${UnitType.Minutes}`;
  const secondsValueFormatted = `${secondRowSecondValue * 60} sek`;

  const thirdRowFirstValue = secondRowResultValue;
  const thirdRowFirstValueFormatted = secondRowResultValueFormatted;
  const thirdRowSecondValue = 60;
  const thirdRowSecondValueFormatted = `${60} ${UnitType.MinutesPerTime}`;
  const thirdRowResultValue = thirdRowFirstValue * thirdRowSecondValue;
  const thirdRowResultValueFormatted = `${roundNumber(thirdRowResultValue)} ${
   UnitType.MlPerTime
  }`;

  const fourthRowFirstValue = secondRowResultValue;
  const fourthRowFirstValueFormatted = thirdRowFirstValueFormatted;
  const fourthRowSecondValue = 20;
  const fourthRowSecondValueFormatted = `${fourthRowSecondValue} ${UnitType.DraperPerMl}`;
  const fourthRowResultValue = fourthRowFirstValue * fourthRowSecondValue;
  const fourthRowResultValueFormatted = `${roundNumber(fourthRowResultValue)} ${
   UnitType.DraperPerMin
  }`;

  const fifthRowFirstValue = fourthRowResultValue;
  const fifthRowFirstValueFormatted = fourthRowResultValueFormatted;
  const fifthRowSecondValue = 60;
  const fifthRowSecondValueFormatted = `${roundNumber(fifthRowSecondValue)} ${
   UnitType.SecondPerMinutes
  }`;
  const fifthRowResultValue = fifthRowFirstValue / fifthRowSecondValue;
  const fifthRowResultValueFormatted = `${roundNumber(fifthRowResultValue)} ${
   UnitType.DraperPerSek
  }`;

  let hideFourthValues = true;
  let fourthValueResult;

  if (hasFourthScreen()) {
   hideFourthValues = false;
   fourthValueResult = `${roundNumber(
    firstRowFirstValue / values[InfusionScreen.AddedDrug.Fourth],
   )} ${UnitType.Ml}`;
  }

  const draperValue = `${values[InfusionScreen.AddedDrug.Fifth] * 20} ${
   UnitType.Draper
  }`;

  const newOperationsResult: ResultOperation[] = [
   {
    firstValue: secondRowFirstValueFormatted,
    operation: MathOperation.division,
    secondValue: formatTimerString(secondRowSecondValueFormatted),
    resultValue: thirdRowResultValueFormatted,
    highlighted: true,
   },
   {
    firstValue: secondRowFirstValueFormatted,
    operation: MathOperation.division,
    secondValue: minutesValueFormatted,
    resultValue: thirdRowFirstValueFormatted,
    highlighted: true,
   },
   {
    firstValue: secondRowFirstValueFormatted,
    operation: MathOperation.times,
    secondValue: fourthRowSecondValueFormatted,
    resultValue: draperValue,
    highlighted: true,
   },
   {
    firstValue: draperValue,
    operation: MathOperation.division,
    secondValue: minutesValueFormatted,
    resultValue: fourthRowResultValueFormatted,
    highlighted: true,
   },
   {
    firstValue: draperValue,
    operation: MathOperation.division,
    secondValue: secondsValueFormatted,
    resultValue: fifthRowResultValueFormatted,
    highlighted: true,
   },
   {
    firstValue: `${conversionFirstValue}/kg`,
    secondValue: description[1],
    operation: MathOperation.times,
    resultValue: firstRowFirstValueFormatted,
    highlighted: true,
    hide: hideFourthValues || !description[InfusionScreen.AddedDrug.Second],
   },
   {
    firstValue: firstRowFirstValueFormatted,
    operation: MathOperation.division,
    secondValue: description[3],
    resultValue: fourthValueResult,
    highlighted: true,
   },
   {
    firstValue: firstRowFirstValueFormatted,
    operation: MathOperation.division,
    secondValue: minutesValueFormatted,
    resultValue: firstRowResultValueFormatted,
    highlighted: true,
   },
  ];
  const finalResult = [...infusionAddedDrugFinalResult];
  finalResult[0].calculatorValue = {
   ...finalResult[0].calculatorValue,
   value: fourthValueResult,
  };
  finalResult[1].calculatorValue = {
   ...finalResult[1].calculatorValue,
   value: thirdRowResultValueFormatted,
  };
  finalResult[2].calculatorValue = {
   ...finalResult[2].calculatorValue,
   value: secondRowResultValueFormatted,
  };
  finalResult[3].calculatorValue = {
   ...finalResult[3].calculatorValue,
   value: fourthRowResultValueFormatted,
  };
  finalResult[4].calculatorValue = {
   ...finalResult[4].calculatorValue,
   value: fifthRowResultValueFormatted,
  };
  finalResult[5].calculatorValue = {
   ...finalResult[5].calculatorValue,
   value: firstRowResultValueFormatted,
  };
  console.log('description', description);
  console.log('fr', finalResult);
  console.log('nop', newOperationsResult);
  setInfusionAddedDrugFinalResult(finalResult);
  setInfusionAddedDrugOperationsResult(newOperationsResult);
 };

 const getInfoTextMlValue = () => {
  const valuesPerWeight = getValuesPerWeightFromResultRows(
   infusionAddedDrugResultRows,
  );
  const description = getDescriptionsFromResultRows(
   infusionAddedDrugResultRows,
  );
  let result =
   valuesPerWeight[InfusionScreen.AddedDrug.First] /
   valuesPerWeight[InfusionScreen.AddedDrug.Fourth];
  if (description[InfusionScreen.AddedDrug.Second]) {
   const units = getUnitsFromResultRows(infusionAddedDrugResultRows);
   const values = getValuesFromResultRows(infusionAddedDrugResultRows);
   let factor = values[InfusionScreen.AddedDrug.Second];
   if (units[InfusionScreen.AddedDrug.Second]?.description === UnitType.Gram) {
    factor /= 1000;
   }
   result *= factor;
  }
  return roundNumber(result, 2);
 };

 const fillInfoText = () => {
  if (
   infusionAddedDrugResultRows[InfusionScreen.AddedDrug.Fourth]?.calculatorValue
    ?.value
  ) {
   setInfusionAddedDrugInfoText(
    `Husk å inkludere det tilsatte legemiddelet (${getInfoTextMlValue()} ml) i totalvolumet. Følg lokal prosedyre for inkludering av skyllevolum.`,
   );
  } else {
   setInfusionAddedDrugInfoText(
    'Husk å inkludere legemidlet i totalvolumet. Følg lokal prosedyre for inkludering av skyllevolum.',
   );
  }
 };

 useEffect(() => {
  setResultArray(infusionAddedDrugFinalResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionAddedDrugFinalResult]);

 useEffect(() => {
  calculateResult();
  setResultRows(infusionAddedDrugResultRows);
  saveHistory();
  fillInfoText();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionAddedDrugResultRows]);

 useEffect(() => {
  setResultOperations(infusionAddedDrugOperationsResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionAddedDrugOperationsResult]);

 useEffect(() => {
  setInfusionAddedDrugResultRows(
   infusionAddedDrugResultRows?.map((input, index) =>
    index > currentScreen ? { ...input, calculatorValue: undefined } : input,
   ),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [currentScreen]);

 useEffect(() => {
  setResultsCalculationHistory(calculationHistory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [calculationHistory]);

 return (
  <InfusionAddedDrugContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    currentScreen,
    setCurrentScreen,
    homeRedirect,
    firstScreenRedirect,
    secondScreenRedirect,
    thirdScreenRedirect,
    fourthScreenRedirect,
    fifthScreenRedirect,
    sixthScreenRedirect,
    resultsRedirect,
    infusionAddedDrugResultRows,
    setInfusionAddedDrugResultRows,
    onSelectChange,
    onValueChange,
    onValueTimerChange,
    getValueInitialState,
    getValueTimerInitialState,
    getSelectInitialState,
    selectedUnit,
    setSelectedUnit,
    hasFourthScreen,
    calculationHistory,
    setCalculationHistory,
    loadHistory,
    infusionAddedDrugInfoText,
    setInfusionAddedDrugInfoText,
   }}
  >
   {children}
  </InfusionAddedDrugContext.Provider>
 );
}
