import React, { useEffect, useState } from 'react';
import { Grid, Flex, Button, HStack } from '@chakra-ui/react';

import { BsBackspace } from 'react-icons/bs';

import { CalculatorOption } from '../../../types';
import { removeLastLetter } from '../../../utils';

interface CalculatorKeyboardComponentProps {
 onHandleChangeInputValue: (value: string) => void;
 inputValue: string;
 hasComma?: boolean;
}

function CalculatorKeyboardComponent({
 inputValue,
 onHandleChangeInputValue,
 hasComma,
}: CalculatorKeyboardComponentProps): React.ReactElement {
 const defaultDigits: Array<Array<CalculatorOption>> = [
  [CalculatorOption.One, CalculatorOption.Two, CalculatorOption.Three],
  [CalculatorOption.Four, CalculatorOption.Five, CalculatorOption.Six],
  [CalculatorOption.Seven, CalculatorOption.Eight, CalculatorOption.Nine],
  [CalculatorOption.Comma, CalculatorOption.Zero, CalculatorOption.Backspace],
 ];

 const [digits, setDigits] = useState(defaultDigits);

 const onHandleChangeLocal = (value: CalculatorOption | string) => {
  if (value === CalculatorOption.Empty) {
   return;
  }

  if (value === CalculatorOption.Backspace) {
   onHandleChangeInputValue(removeLastLetter(inputValue));
  } else {
   onHandleChangeInputValue(`${inputValue}${value}`);
  }
 };

 useEffect(() => {
  if (!hasComma) {
   setDigits(
    defaultDigits.map(row =>
     row.map(digit =>
      digit === CalculatorOption.Comma ? CalculatorOption.Empty : digit,
     ),
    ),
   );
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [hasComma]);

 return (
  <Grid
   w="100%"
   minHeight="350px"
   h="100%"
   bg="blue.100"
   p="10px"
   borderTop="8px solid"
   borderColor="gray.500"
  >
   {digits.map(row => (
    <HStack
     key={JSON.stringify(row)}
     justifyContent="space-evenly"
     spacing="15px"
    >
     {row.map(digit => (
      <Flex key={digit} justifyContent="space-around" w="100%" h="100%">
       <Button
        w="100%"
        h="80%"
        borderRadius="10px"
        fontSize="48px"
        type="button"
        onClick={() => onHandleChangeLocal(digit)}
       >
        {digit !== CalculatorOption.Backspace ? (
         digit
        ) : (
         <BsBackspace size={32} />
        )}
       </Button>
      </Flex>
     ))}
    </HStack>
   ))}
  </Grid>
 );
}

CalculatorKeyboardComponent.defaultProps = {
 hasComma: true,
};

export default CalculatorKeyboardComponent;
