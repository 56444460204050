import React, { useContext, useEffect } from 'react';

import { GramUnits, InfusionScreen, UnitType } from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { InfusionAddedDrugContext } from '../../../../contexts/InfusionProvider/InfusionAddedDrugProvider';
import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';

function InfusionAddedDrugSecondScreen(): React.ReactElement {
 const { setBackButtonText, setNextButtonAction, setBackButtonAction } =
  useContext(CalculatorNavigationContext);

 const {
  setCurrentScreen,
  onValueChange,
  onSelectChange,
  getSelectInitialState,
  getValueInitialState,
 } = useContext(InfusionAddedDrugContext);

 const { setHasCheckbox, setQuestion, setUnitTypes } =
  useContext(CalculatorContext);

 const unitTypes = GramUnits.filter(unit =>
  [UnitType.Gram, UnitType.Kilogram].includes(unit.description),
 );

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(
    () => () => setCurrentScreen(InfusionScreen.AddedDrug.First),
   );
  };
  const setNextButtonProps = () => {
   setNextButtonAction(
    () => () => setCurrentScreen(InfusionScreen.AddedDrug.Third),
   );
  };
  setBackButtonProps();
  setNextButtonProps();
 };

 const setCalculatorProps = () => {
  setHasCheckbox(false);
  setQuestion('Hvor mye veier pasienten?');
  setUnitTypes(unitTypes);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <CalculatorComponent
   onValueChange={onValueChange}
   onSelectChange={onSelectChange}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default InfusionAddedDrugSecondScreen;
