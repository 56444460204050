import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface questionProps {
 question?: string;
 title?: string;
}

function QuestionComponent({
 question,
 title,
}: questionProps): React.ReactElement {
 return (
  <Flex
   alignItems="center"
   justifyContent="center"
   textAlign="center"
   flexDirection="column"
   px="30px"
   width="100%"
  >
   {title && (
    <Text
     fontSize="24px"
     lineHeight="24px"
     textAlign="center"
     mb={10}
     fontWeight="bold"
    >
     {title}
    </Text>
   )}
   <Text fontSize="24px" lineHeight="24px" textAlign="center" mb={2}>
    {question}
   </Text>
  </Flex>
 );
}

QuestionComponent.defaultProps = {
 question: '',
 title: '',
};

export default QuestionComponent;
