import React, { useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { AppRoutes, MlUnits } from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';
import { InfusionWithoutAdditiveContext } from '../../../../contexts/InfusionProvider/InfusionWithoutAdditiveProvider';

function InfusionWithoutAdditiveFirstScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setNextButtonAction,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const {
  secondScreenRedirect,
  onSelectChange,
  onValueChange,
  getValueInitialState,
  getSelectInitialState,
  setSelectedUnit,
  setCurrentScreen,
 } = useContext(InfusionWithoutAdditiveContext);

 const {
  setHasCheckbox,
  setPageTitle,
  setQuestion,
  setUnitTypes,
  setShowInfo,
  setInfoText,
 } = useContext(CalculatorContext);

 const navigate = useNavigate();

 const homeRedirect = () => () => {
  setShowInfo(false);
  setCurrentScreen(-1);
  navigate(AppRoutes.Infusion);
 };

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(homeRedirect);
  };
  const setNextButtonProps = () => {
   setNextButtonText('Neste');
   setNextButtonAction(secondScreenRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };

 const setCalculatorProps = () => {
  setHasCheckbox(false);
  setPageTitle('Infusjon - Ingen tilsetning');
  setQuestion('Hva er infusjonsposens totale volum?');
  setUnitTypes(MlUnits);
  setInfoText('Følg lokal prosedyre for inkludering av skyllevolum.');
  setShowInfo(true);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <CalculatorComponent
   onValueChange={onValueChange}
   onSelectChange={value => {
    onSelectChange(value);
    setSelectedUnit(value);
   }}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default InfusionWithoutAdditiveFirstScreen;
