import React, { useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
 AppRoutes,
 GramUnits,
 IEUnits,
 MolUnits,
 UnitType,
} from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { InjectionContext } from '../../../../contexts/InjectionProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';

function LiquidMiksturFirstScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setNextButtonAction,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const {
  secondScreenRedirect,
  thirdScreenRedirect,
  onSelectChange,
  onValueChange,
  getValueInitialState,
  getSelectInitialState,
  setSelectedUnit,
 } = useContext(InjectionContext);

 const {
  setHasCheckbox,
  setPageTitle,
  setQuestion,
  setCheckboxText,
  setUnitTypes,
  setCheckboxAction,
  checkboxValue,
 } = useContext(CalculatorContext);

 const unitTypes = [
  ...GramUnits.filter(
   unit =>
    ![UnitType.Kilogram, UnitType.KilogramPerMl].includes(unit.description),
  ),
  ...MolUnits,
  ...IEUnits,
 ];

 const navigate = useNavigate();

 const homeRedirect = () => () => {
  navigate(AppRoutes.Liquid);
 };

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(homeRedirect);
  };
  const setNextButtonProps = () => {
   setNextButtonText('Neste');
   setNextButtonAction(thirdScreenRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };

 const setCalculatorProps = () => {
  setHasCheckbox(true);
  setPageTitle('Flytende - Mikstur');
  setQuestion('Hva er den ordinerte dosen?');
  setCheckboxText('pr kg av pasientens kroppsvekt');
  setUnitTypes(unitTypes);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  if (checkboxValue) {
   setCheckboxAction(() => setNextButtonAction(secondScreenRedirect));
  } else {
   setCheckboxAction(() => setNextButtonAction(thirdScreenRedirect));
  }
 }, [
  checkboxValue,
  secondScreenRedirect,
  setCheckboxAction,
  setNextButtonAction,
  thirdScreenRedirect,
 ]);

 return (
  <CalculatorComponent
   onValueChange={onValueChange}
   onSelectChange={value => {
    onSelectChange(value);
    setSelectedUnit(`${value}/${UnitType.Ml}`);
   }}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default LiquidMiksturFirstScreen;
