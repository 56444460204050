import React, { useContext, useEffect } from 'react';

import { InfusionScreen, MlUnits } from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { InfusionWithoutAdditiveContext } from '../../../../contexts/InfusionProvider/InfusionWithoutAdditiveProvider';
import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';
import { isValidInputValueTimer } from '../../../../utils';

function InfusionWithoutAdditiveSecondScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setNextButtonAction,
  setNextButtonDisabled,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { setCurrentScreen, onValueTimerChange, getValueTimerInitialState } =
  useContext(InfusionWithoutAdditiveContext);

 const { setHasCheckbox, setQuestion, setUnitTypes, setShowInfo, setIsTimer } =
  useContext(CalculatorContext);

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(() => () => {
    setCurrentScreen(InfusionScreen.WithoutAdditive.First);
    setIsTimer(false);
   });
  };
  const setNextButtonProps = () => {
   setNextButtonText('Regn ut');
   setNextButtonAction(() => () => {
    setCurrentScreen(InfusionScreen.WithoutAdditive.Result);
    setIsTimer(false);
   });
  };
  setBackButtonProps();
  setNextButtonProps();
 };

 const setCalculatorProps = () => {
  setHasCheckbox(false);
  setQuestion('Hvor lenge skal infusjonen vare?');
  setUnitTypes(MlUnits);
  setShowInfo(false);
  setIsTimer(true);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  if (isValidInputValueTimer(getValueTimerInitialState())) {
   setNextButtonDisabled(false);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <CalculatorComponent
   onValueTimerChange={onValueTimerChange}
   valueTimerInitialState={getValueTimerInitialState()}
  />
 );
}

export default InfusionWithoutAdditiveSecondScreen;
