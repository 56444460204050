import React, { useEffect, useContext } from 'react';

import ResultsComponent from '../../../../components/ResultsComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { DilutionAdditionVolumeContext } from '../../../../contexts/DilutionProvider/DilutionAdditionVolumeProvider';
import { ResultsContext } from '../../../../contexts/ResultsProvider';

interface DilutionAdditionVolumeResultScreenProps {
 description?: string;
}

function DilutionAdditionVolumeResultScreen({
 description,
}: DilutionAdditionVolumeResultScreenProps): React.ReactElement {
 const {
  setNextButtonAction,
  setNextButtonText,
  setBackButtonText,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { thirdScreenRedirect, homeRedirect } = useContext(
  DilutionAdditionVolumeContext,
 );

 const { setHasMultipleResults } = useContext(ResultsContext);

 const setNavigationProps = () => {
  const setNextButtonProps = () => {
   setNextButtonAction(homeRedirect);
   setNextButtonText('Ferdig');
  };
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(thirdScreenRedirect);
  };
  setNextButtonProps();
  setBackButtonProps();
 };

 useEffect(() => {
  setHasMultipleResults(false);
  setNavigationProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return <ResultsComponent description={description} />;
}

DilutionAdditionVolumeResultScreen.defaultProps = {
 description: 'Volum, tilsetning',
};

export default DilutionAdditionVolumeResultScreen;
