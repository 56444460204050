import React, { useEffect, useContext } from 'react';

import ResultsComponent from '../../../components/ResultsComponent';

import { PillsContext } from '../../../contexts/PillsProvider';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';
import { UnitType } from '../../../types';

function PillsResultScreen(): React.ReactElement {
 const {
  setNextButtonAction,
  setNextButtonText,
  setBackButtonText,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { setFinalResultUnit, setHasMultipleResults } =
  useContext(ResultsContext);

 const { thirdScreenRedirect, homeRedirect } = useContext(PillsContext);

 const setNavigationProps = () => {
  const setNextButtonProps = () => {
   setNextButtonAction(homeRedirect);
   setNextButtonText('Ferdig');
  };
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(thirdScreenRedirect);
  };
  setNextButtonProps();
  setBackButtonProps();
 };

 useEffect(() => {
  setNavigationProps();
  setFinalResultUnit(UnitType.Tablett);
  setHasMultipleResults(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return <ResultsComponent />;
}

export default PillsResultScreen;
