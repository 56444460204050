import React from 'react';
import { Routes as Router, Route } from 'react-router-dom';

import { Pages } from '../pages';

import {
 AppRoutes,
 DilutionRoutes,
 InfusionRoutes,
 LiquidRoutes,
} from '../types';

function Routes(): React.ReactElement {
 return (
  <Router>
   <Route path="/" element={<Pages.Home />} />
   <Route path={AppRoutes.Home} element={<Pages.Home />} />
   <Route path={AppRoutes.Dilution} element={<Pages.Dilution.Home />} />
   <Route
    path={DilutionRoutes.AdditionVolume}
    element={<Pages.Dilution.AdditionVolume />}
   />
   <Route
    path={DilutionRoutes.StrengthDiluted}
    element={<Pages.Dilution.StrengthDiluted />}
   />
   <Route path={AppRoutes.Infusion} element={<Pages.Infusion.Home />} />
   <Route
    path={InfusionRoutes.AddedDrug}
    element={<Pages.Infusion.AddedDrug />}
   />
   <Route
    path={InfusionRoutes.WithoutAdditive}
    element={<Pages.Infusion.WithoutAdditive />}
   />
   <Route path={InfusionRoutes.PerTime} element={<Pages.Infusion.PerTime />} />
   <Route path={AppRoutes.Injection} element={<Pages.Injection />} />
   <Route path={AppRoutes.Liquid} element={<Pages.Liquid.Home />} />
   <Route path={LiquidRoutes.Draper} element={<Pages.Liquid.Draper />} />
   <Route path={LiquidRoutes.Mikstur} element={<Pages.Liquid.Mikstur />} />
   <Route path={AppRoutes.Pills} element={<Pages.Pills />} />
   <Route path="*" element={<Pages.NotFound />} />
  </Router>
 );
}

export default Routes;
