import React, { useContext, useEffect, useState } from 'react';

import { GramUnits, IEUnits, MolUnits, UnitType } from '../../../types';

import CalculatorComponent from '../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { PillsContext } from '../../../contexts/PillsProvider';
import { CalculatorContext } from '../../../contexts/CalculatorProvider';

import { getUnitTypeByString } from '../../../utils';

function PillsThirdScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setBackButtonAction,
  setNextButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { setQuestion, setUnitTypes, checkboxValue, setHasCheckbox } =
  useContext(CalculatorContext);

 const {
  firstScreenRedirect,
  secondScreenRedirect,
  resultsRedirect,
  onValueChange,
  onSelectChange,
  getSelectInitialState,
  getValueInitialState,
  selectedUnit,
 } = useContext(PillsContext);

 const gramOptions = [UnitType.Microgram, UnitType.Miligram, UnitType.Gram];

 const molOptions = [UnitType.Micromol, UnitType.Milimol, UnitType.Mol];

 const [localUnitTypes, setLocalUnitTypes] = useState(GramUnits);

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
  };
  const setNextButtonProps = () => {
   setNextButtonText('Regn ut');
   setNextButtonAction(resultsRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };
 const setCalculatorProps = () => {
  setQuestion('Hvilken styrke har hver tablett (eller tilsvarende)?');
  setUnitTypes(localUnitTypes);
  setHasCheckbox(false);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  if (checkboxValue) {
   setBackButtonAction(secondScreenRedirect);
  } else {
   setBackButtonAction(firstScreenRedirect);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [checkboxValue]);

 useEffect(() => {
  if (gramOptions.includes(getUnitTypeByString(selectedUnit))) {
   setLocalUnitTypes(
    GramUnits.filter(
     unit =>
      ![UnitType.Kilogram, UnitType.KilogramPerMl].includes(unit.description),
    ),
   );
  } else if (molOptions.includes(getUnitTypeByString(selectedUnit))) {
   setLocalUnitTypes(MolUnits);
  } else {
   setLocalUnitTypes(IEUnits);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  setUnitTypes(localUnitTypes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [localUnitTypes]);

 return (
  <CalculatorComponent
   onValueChange={(value: string) => onValueChange(value)}
   onSelectChange={onSelectChange}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default PillsThirdScreen;
