import { Flex, HStack, VStack, Text, Image, Link } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { To, useNavigate } from 'react-router-dom'
import Question from '../../../components/QuestionComponent'

import imageDraper from '../../../assets/icons/icon-draper.svg'
import imageMikstur from '../../../assets/icons/icon-mikstur.svg'

import { LiquidRoutes } from '../../../types'

import CalculatorNavigationComponent from '../../../components/CalculatorComponent/CalculatorNavigationComponent'
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider'
import { InjectionContext } from '../../../contexts/InjectionProvider'
import Header from '../../../components/HeaderComponent'

function LiquidHomePage(): React.ReactElement {
 const navigate = useNavigate()

 const { setBackButtonAction, setBackButtonText, setHomeButtonAction } =
  useContext(CalculatorNavigationContext)

 const handleClick = (path: To) => {
  navigate(path)
 }

 const { homeRedirect } = useContext(InjectionContext)

 useEffect(() => {
  setBackButtonAction(homeRedirect)
  setBackButtonText('Avbryt')
  setHomeButtonAction(homeRedirect)
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

 return (
  <Flex flexDirection="column" w="100%">
   <CalculatorNavigationComponent hasNextButton={false} />
   <Flex w="100%" py="30px" px="30px">
    <Header />
   </Flex>
   <Flex flexDirection="column" px="30px" pt="30px" w="100%" h="100%">
    <Question
     title="Flytende"
     question="I hvilken form skal legemiddelet gis?"
    />
    <VStack mt="auto" bg="gray.400" mx="-30px" px="40px">
      <Link w="100%" borderBottom="1px solid" borderColor="black.500" h="130px" onClick={() => handleClick(LiquidRoutes.Draper)}>
        <HStack
          h="130px"
          alignItems="center"
          spacing="30px"
        >
          <Image src={imageDraper} w="32px" />
          <Text
          fontWeight="600"
          fontSize="24"
          w="100%"
          >
          Dråper
          </Text>
        </HStack>
     </Link>
     <Link w="100%" h="130px" onClick={() => handleClick(LiquidRoutes.Mikstur)}>
      <HStack
        h="130px"
        alignItems="center"
        spacing="30px"
      >
        <Image src={imageMikstur} w="32px" />
        <Text fontWeight="600" fontSize="24" w="100%">
        Mikstur
        </Text>
      </HStack>
     </Link>
    </VStack>
   </Flex>
  </Flex>
 )
}

export default LiquidHomePage
