export enum AppRoutes {
 Home = '/home',
 Pills = '/tabletter',
 Liquid = '/flytende',
 Injection = '/injeksjon',
 Infusion = '/infusjon',
 Dilution = '/fortynning',
}

export enum LiquidRoutes {
 Draper = '/flytende/draper',
 Mikstur = '/flytende/mikstur',
}

export enum DilutionRoutes {
 AdditionVolume = '/fortynning/tilsetningsvolum',
 StrengthDiluted = '/fortynning/styrke-fortynnet',
}

export enum InfusionRoutes {
 AddedDrug = '/infusjon/tilsatt-legemiddel',
 WithoutAdditive = '/infusjon/uten-tilsetning',
 PerTime = '/infusjon/nar-dosen-er-angitt-pr-minutt-eller-time',
}

export interface ListElement {
 icon: string;
 title: string;
 path: string;
}

export enum UnitType {
 Microgram = 'μg',
 MicrogramPerMl = 'μg/ml',
 MicrogramPerTime = 'μg/t',
 MicrogramPerMinute = 'μg/min',
 Miligram = 'mg',
 MiligramPerMl = 'mg/ml',
 MiligramPerTime = 'mg/t',
 MiligramPerMinute = 'mg/min',
 Gram = 'g',
 GramPerMl = 'g/ml',
 GramPerTime = 'g/t',
 GramPerMinute = 'g/min',
 Kilogram = 'kg',
 KilogramPerMl = 'kg/ml',
 KilogramPerTime = 'kg/t',
 KilogramPerMinute = 'kg/min',
 Micromol = 'μmol',
 MicromolPerMl = 'μmol/ml',
 MicromolPerTime = 'μmol/t',
 MicromolPerMinute = 'μmol/min',
 Milimol = 'mmol',
 MilimolPerMl = 'mmol/ml',
 MilimolPerTime = 'mmol/t',
 MilimolPerMinute = 'mmol/min',
 Mol = 'mol',
 MolPerMl = 'mol/ml',
 MolPerTime = 'mol/t',
 MolPerMinute = 'mol/min',
 IE = 'IE',
 IEPerMl = 'IE/ml',
 IEPerTime = 'IE/t',
 IEPerMinute = 'IE/min',
 Empty = '',
 Draper = 'dråper',
 DraperPerMl = 'dråper/ml',
 DraperPerMin = 'dråper/min',
 DraperPerSek = 'dråper/sek',
 Ml = 'ml',
 MlPerMinute = 'ml/min',
 MlPerTime = 'ml/t',
 Tablett = 'tablett',
 Timer = 't',
 Minutes = 'min',
 MinutesPerTime = 'min/t',
 SecondPerMinutes = 's/min',
}

export interface UnitOfMeasurement {
 description: UnitType;
 weight: number;
 perMl?: boolean;
}

export const GramUnits: Array<UnitOfMeasurement> = [
 { description: UnitType.Microgram, weight: 1e-6 },
 { description: UnitType.Miligram, weight: 0.001 },
 { description: UnitType.Gram, weight: 1 },
 { description: UnitType.Kilogram, weight: 1000 },
]

export const GramUnitsPerMl: Array<UnitOfMeasurement> = [
 { description: UnitType.MicrogramPerMl, weight: 1e-6 },
 { description: UnitType.MiligramPerMl, weight: 0.001 },
 { description: UnitType.GramPerMl, weight: 1 },
 { description: UnitType.KilogramPerMl, weight: 1000 },
]

export const GramUnitsPerTime: Array<UnitOfMeasurement> = [
 { description: UnitType.MicrogramPerTime, weight: 1e-6 },
 { description: UnitType.MiligramPerTime, weight: 0.001 },
 { description: UnitType.GramPerTime, weight: 1 },
 { description: UnitType.KilogramPerTime, weight: 1000 },
]

export const GramUnitsPerMinute: Array<UnitOfMeasurement> = [
 { description: UnitType.MicrogramPerMinute, weight: 1e-6 },
 { description: UnitType.MiligramPerMinute, weight: 0.001 },
 { description: UnitType.GramPerMinute, weight: 1 },
 { description: UnitType.KilogramPerMinute, weight: 1000 },
]

export const MolUnits: Array<UnitOfMeasurement> = [
 { description: UnitType.Micromol, weight: 1e-6 },
 { description: UnitType.Milimol, weight: 0.001 },
 { description: UnitType.Mol, weight: 1 },
]

export const MolUnitsPerMl: Array<UnitOfMeasurement> = [
 { description: UnitType.MicromolPerMl, weight: 1e-6 },
 { description: UnitType.MilimolPerMl, weight: 0.001 },
 { description: UnitType.MolPerMl, weight: 1 },
]

export const MolUnitsPerTime: Array<UnitOfMeasurement> = [
 { description: UnitType.MicromolPerTime, weight: 1e-6 },
 { description: UnitType.MilimolPerTime, weight: 0.001 },
 { description: UnitType.MolPerTime, weight: 1 },
]

export const MolUnitsPerMinute: Array<UnitOfMeasurement> = [
 { description: UnitType.MicromolPerMinute, weight: 1e-6 },
 { description: UnitType.MilimolPerMinute, weight: 0.001 },
 { description: UnitType.MolPerMinute, weight: 1 },
]

export const IEUnits: Array<UnitOfMeasurement> = [
 { description: UnitType.IE, weight: 1 },
]

export const IEUnitsPerMl: Array<UnitOfMeasurement> = [
 { description: UnitType.IEPerMl, weight: 1 },
]

export const IEUnitsPerTime: Array<UnitOfMeasurement> = [
 { description: UnitType.IEPerTime, weight: 1 },
]

export const IEUnitsPerMinute: Array<UnitOfMeasurement> = [
 { description: UnitType.IEPerMinute, weight: 1 },
]

export const DraperUnits: Array<UnitOfMeasurement> = [
 { description: UnitType.Draper, weight: 1 },
]

export const MlUnits: Array<UnitOfMeasurement> = [
 { description: UnitType.Ml, weight: 1 },
]

export enum CalculatorOption {
 Backspace = '<-',
 Comma = ',',
 Zero = '0',
 One = '1',
 Two = '2',
 Three = '3',
 Four = '4',
 Five = '5',
 Six = '6',
 Seven = '7',
 Eight = '8',
 Nine = '9',
 Empty = '',
}

export enum PillsScreen {
 First = 0,
 Second,
 Third,
 Result,
}

export enum InjectionScreen {
 First = 0,
 Second,
 Third,
 Result,
}

export enum LiquidScreen {
 First = 0,
 Second,
 Third,
 Fourth,
 Result,
}

export enum DilutionScreen {
 First = 0,
 Second,
 Third,
 Result,
}

export const InfusionScreen = {
 AddedDrug: {
  First: 0,
  Second: 1,
  Third: 2,
  Fourth: 3,
  Fifth: 4,
  Sixth: 5,
  Result: 6,
 },
 WithoutAdditive: {
  First: 0,
  Second: 1,
  Result: 2,
 },
 PerTime: {
  First: 0,
  Second: 1,
  Third: 2,
  Result: 3,
 },
}

export interface CalculatorValue {
 unit?: UnitOfMeasurement;
 value?: string;
}

export interface ResultRow {
 description?: string;
 calculatorValue?: CalculatorValue | undefined;
}

export interface CanShowModal {
 showModal(): void;
}

export interface ResultOperation {
 firstValue?: string;
 operation?: string;
 secondValue?: string;
 resultValue?: string;
 highlighted?: boolean;
 hide?: boolean;
}

export enum MathOperation {
 division = '/',
 times = 'x',
 plus = '+',
 minus = '-',
}

export enum BooleanType {
 true = 'true',
 false = 'false',
}

export enum InputTimer {
 Timer = 'timer',
 Minutter = 'minutter',
}

export interface CalculationHistory {
 redirectTo?: string;
 title: string;
 date?: Date;
 data?: any;
 index?: number;
}
