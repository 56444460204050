import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'

import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/source-sans-pro'
import { theme } from './styles'

import App from './App'

import { CalculatorNavigationProvider } from './contexts/CalculatorNavigationProvider'
import { PillsProvider } from './contexts/PillsProvider'
import { CalculatorProvider } from './contexts/CalculatorProvider'
import { ResultsProvider } from './contexts/ResultsProvider'
import { InjectionProvider } from './contexts/InjectionProvider'
import { LiquidProvider } from './contexts/LiquidProvider'
import { DilutionAdditionVolumeProvider } from './contexts/DilutionProvider/DilutionAdditionVolumeProvider'
import { DilutionStrengthDilutedProvider } from './contexts/DilutionProvider/DilutionStrengthDilutedProvider'
import { InfusionWithoutAdditiveProvider } from './contexts/InfusionProvider/InfusionWithoutAdditiveProvider'
import { InfusionAddedDrugProvider } from './contexts/InfusionProvider/InfusionAddedDrugProvider'
import { InfusionPerTimeProvider } from './contexts/InfusionProvider/InfusionPerTimeProvider'
import { AppProvider } from './contexts/AppProvider'

import * as serviceWorkerRegistration from './serviceWorkerRegistration.js'

ReactDOM.render(
 <React.StrictMode>
  <HashRouter>
   <CalculatorProvider>
    <AppProvider>
     <ResultsProvider>
      <CalculatorNavigationProvider>
       <DilutionAdditionVolumeProvider>
        <InfusionWithoutAdditiveProvider>
         <InfusionAddedDrugProvider>
          <InfusionPerTimeProvider>
           <DilutionStrengthDilutedProvider>
            <LiquidProvider>
             <InjectionProvider>
              <PillsProvider>
               <ChakraProvider theme={theme}>
                <App />
               </ChakraProvider>
              </PillsProvider>
             </InjectionProvider>
            </LiquidProvider>
           </DilutionStrengthDilutedProvider>
          </InfusionPerTimeProvider>
         </InfusionAddedDrugProvider>
        </InfusionWithoutAdditiveProvider>
       </DilutionAdditionVolumeProvider>
      </CalculatorNavigationProvider>
     </ResultsProvider>
    </AppProvider>
   </CalculatorProvider>
  </HashRouter>
 </React.StrictMode>,
 document.getElementById('root'),
)

 serviceWorkerRegistration.register()
