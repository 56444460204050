import React, { useContext, useState } from 'react';

import {
 VStack,
 Text,
 Table,
 Tr,
 Td,
 Tbody,
 Flex,
 Button,
 HStack,
} from '@chakra-ui/react';

import Modal from 'react-modal';
import { RiErrorWarningFill } from 'react-icons/ri';
import { GrClose } from 'react-icons/gr';

import { CalculatorContext } from '../../contexts/CalculatorProvider';
import { ResultsContext } from '../../contexts/ResultsProvider';
import CalculatorNavigationComponent from '../CalculatorComponent/CalculatorNavigationComponent';
import { UnitType } from '../../types';
import { getTabletterUnit } from '../../utils';
import Header from '../HeaderComponent';

interface ResultsComponentProps {
 description?: string;
}

function ResultsComponent({
 description,
}: ResultsComponentProps): React.ReactElement {
 const { pageTitle, checkboxValue } = useContext(CalculatorContext);

 const {
  resultRows,
  finalResultValue,
  finalResultUnit,
  resultOperations,
  hasMultipleResults,
  resultArray,
 } = useContext(ResultsContext);

 const [modalIsOpen, setModalIsOpen] = useState(false);

 const openModal = () => {
  setModalIsOpen(true);
 };

 const closeModal = () => {
  setModalIsOpen(false);
 };

 const formatInputValue = (inputValue?: string): string | undefined => {
  const splittedValue = inputValue?.split('.');
  if (!splittedValue) {
   return inputValue;
  }

  if (!splittedValue[1]) {
   return inputValue;
  }

  let decimalCases = 2;
  if (parseInt(splittedValue[0], 10) === 0) {
   decimalCases = 3;
  } else if (splittedValue[0].length > 2) {
   decimalCases = 0;
  } else if (splittedValue[0].length === 2) {
   decimalCases = 1;
  }

  decimalCases = 6; // Always show 6 decimal cases

  return `${splittedValue[0]}${
   decimalCases ? `,${splittedValue[1].substring(0, decimalCases)}` : ''
  }
   `;
 };

 const formatResultValue = (resultValue?: string): string | undefined => {
  const splittedResult = resultValue?.split(' ');
  if (!splittedResult) {
   return resultValue;
  }
  if (!splittedResult[1]) {
   return formatInputValue(resultValue);
  }

  return `${formatInputValue(splittedResult[0])} ${splittedResult[1]} `;
 };

 const formatInputDescription = (
  inputDescription?: string,
 ): string | undefined => {
  let formattedDescription = inputDescription;
  if (inputDescription === 'Dose' && checkboxValue) {
   formattedDescription += ' per kg';
  }
  return formattedDescription;
 };
 return (
  <VStack w="100%" pb="30px">
   <CalculatorNavigationComponent saveHistory />
   <Flex w="100%" py="30px" px="30px">
    <Header />
   </Flex>
   <VStack px="30px" w="100%" h="100%">
    <Text fontSize="16px" textAlign="center">
     {pageTitle}
    </Text>
    <Text fontSize="28px" textAlign="center" mb="20px">
     Resultat
    </Text>
    <Table overflowY="scroll">
     <Tbody>
      {resultRows?.map(
       input =>
        input.calculatorValue?.value && (
         <Tr key={JSON.stringify(input)}>
          <Td borderColor="gray.500">
           {' '}
           {formatInputDescription(input.description)}{' '}
          </Td>
          <Td borderColor="gray.500" textAlign="right">
           {input.calculatorValue?.value}{' '}
           {input.calculatorValue?.unit?.description}
           {input.description === 'Dose' && checkboxValue
            ? `/${resultRows[1].calculatorValue?.unit?.description}`
            : ''}
          </Td>
         </Tr>
        ),
      )}
      {!hasMultipleResults ? (
       <Tr>
        <Td fontWeight="700" borderColor="gray.500">
         {description}
        </Td>
        <Td fontWeight="700" borderColor="gray.500" textAlign="right">
         {finalResultValue < 0
          ? 0
          : formatResultValue(finalResultValue.toString())}{' '}
         {finalResultUnit !== UnitType.Tablett
          ? finalResultUnit
          : getTabletterUnit(finalResultValue)}
        </Td>
       </Tr>
      ) : (
       resultArray.map(
        result =>
         result &&
         result.calculatorValue &&
         result.calculatorValue.value && (
          <Tr key={JSON.stringify(result)}>
           <Td fontWeight="700" borderColor="gray.500">
            {result.description}
           </Td>
           <Td fontWeight="700" borderColor="gray.500" textAlign="right">
            {formatResultValue(result.calculatorValue?.value)}
            {result.calculatorValue?.unit}
           </Td>
          </Tr>
         ),
       )
      )}
     </Tbody>
    </Table>
    <Modal
     ariaHideApp={false}
     isOpen={modalIsOpen}
     onRequestClose={closeModal}
     style={{
      overlay: {
       position: 'fixed',
       top: 0,
       left: 0,
       right: 0,
       bottom: 0,
       backgroundColor: 'rgba(0, 0, 0, 0.75)',
       zIndex: 2,
      },
      content: {
       top: '50%',
       left: '50%',
       right: 'auto',
       bottom: 'auto',
       marginRight: '-50%',
       transform: 'translate(-50%, -50%)',
       width: '80%',
       maxWidth: '500px',
      },
     }}
    >
     <Flex justifyContent="space-between" mb="30px">
      <Text fontSize="28px">Utregning</Text>
      <Button
       onClick={closeModal}
       p={0}
       bg="white"
       h="auto"
       minWidth={0}
       _hover={{ backgroundColor: 'white' }}
      >
       <GrClose size={32} />
      </Button>
     </Flex>
     <VStack alignItems="flex-start" mb="50px">
      {resultOperations.map(
       (result, index) =>
        result.resultValue &&
        !result.hide && (
         <HStack key={JSON.stringify(result)}>
          {resultOperations.length - 1 !== index && !result.highlighted ? (
           <Text fontWeight="700" fontSize="18px">
            {formatResultValue(result.firstValue)} {result.operation}{' '}
            {formatResultValue(result.secondValue)} ={' '}
            {formatResultValue(result.resultValue)}
           </Text>
          ) : (
           <VStack alignItems="flex-start">
            <Text fontWeight="700" fontSize="18px">
             {formatResultValue(result.firstValue)}{' '}
             {formatResultValue(result.operation)}{' '}
             {formatResultValue(result.secondValue)} ={' '}
             <span style={{ color: '#D14641' }}>
              {formatResultValue(result.resultValue)}
             </span>
            </Text>
           </VStack>
          )}
         </HStack>
        ),
      )}
     </VStack>
    </Modal>
    <Flex
     flexDirection="column"
     mt="auto"
     flex={1}
     justifyContent="flex-end"
     w="100%"
     h="10%"
    >
     <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      marginBottom="1rem"
      marginTop="1rem"
     >
      <RiErrorWarningFill size={24} />
      <Text ml="10px" fontSize="18px">
       Er svaret rimelig?
      </Text>
     </Flex>
    </Flex>
    <Button
     bg="red.500"
     color="#FFFFFF"
     w="100%"
     h="68px"
     fontSize="22px"
     fontWeight="600"
     onClick={openModal}
    >
     Vis utregningen
    </Button>
   </VStack>
  </VStack>
 );
}

ResultsComponent.defaultProps = {
 description: 'Mengde',
};

export default ResultsComponent;
