import React, { useContext } from 'react'

import { To, useNavigate } from 'react-router-dom'
import { HStack, Image, Text, VStack, Flex, Link } from '@chakra-ui/react'
import Header from '../HeaderComponent'
import { PillsContext } from '../../contexts/PillsProvider'

import { AppRoutes, ListElement, PillsScreen } from '../../types'

interface ListComponentProps {
 elements: Array<ListElement>;
}

function ListComponent({ elements }: ListComponentProps): React.ReactElement {
 const { setCurrentScreen } = useContext(PillsContext)
 const navigate = useNavigate()

 const handleClick = (path: To) => {
  switch (path) {
   case AppRoutes.Pills:
    setCurrentScreen(PillsScreen.First)
    break
   default:
    break
  }
  navigate(path)
 }

 return (
  <VStack
   justifyContent="space-between"
   alignContent="space-between"
   w="100%"
   height="100%"
   spacing={0}
   px="30px"
  >
   <Flex py="40px" w="100%">
    <Header />
   </Flex>
   {elements.map(element => (
    <Link w="full" key={element.title} borderBottom="1px solid" borderColor="gray.500" h="20%" onClick={() => handleClick(element.path)}>
      <HStack
      w="100%"
      h="full"
      alignItems="center"
      spacing="30px"
      >
        <Image src={element.icon} w={12} h={12} />
        <Text fontWeight="600" fontSize="24">
          {element.title}
        </Text>
      </HStack>
    </Link>
   ))}
  </VStack>
 )
}

export default ListComponent
