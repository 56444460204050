import React, { useContext, useEffect, useState } from 'react';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { LiquidContext } from '../../../../contexts/LiquidProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';

import { DraperUnits, GramUnits } from '../../../../types';

function LiquidDraperFourthScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setBackButtonAction,
  setNextButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { setQuestion, setUnitTypes, setHasCheckbox } =
  useContext(CalculatorContext);

 const {
  thirdScreenRedirect,
  resultsRedirect,
  onValueChange,
  onSelectChange,
  getSelectInitialState,
  getValueInitialState,
 } = useContext(LiquidContext);

 const [localUnitTypes, setLocalUnitTypes] = useState(GramUnits);

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(thirdScreenRedirect);
  };
  const setNextButtonProps = () => {
   setNextButtonText('Regn ut');
   setNextButtonAction(resultsRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };
 const setCalculatorProps = () => {
  setQuestion('Hvor mange dråper utgjør en ml av legemiddelet?');
  setUnitTypes(localUnitTypes);
  setHasCheckbox(false);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  setLocalUnitTypes(DraperUnits);
 }, []);

 useEffect(() => {
  setUnitTypes(localUnitTypes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [localUnitTypes]);

 return (
  <CalculatorComponent
   onValueChange={(value: string) => onValueChange(value)}
   onSelectChange={onSelectChange}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
   hasComma={false}
  />
 );
}

export default LiquidDraperFourthScreen;
