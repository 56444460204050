import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { InfusionScreen, UnitType } from '../../../types';

import { CalculatorContext } from '../../../contexts/CalculatorProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';
import { InfusionWithoutAdditiveContext } from '../../../contexts/InfusionProvider/InfusionWithoutAdditiveProvider';

import InfusionWithoutAdditivePageFirstScreen from './InfusionWithoutAdditivePageFirstScreen';
import InfusionWithoutAdditivePageSecondScreen from './InfusionWithoutAdditivePageSecondScreen';
import InfusionWithoutAdditivePageResultScreen from './InfusionWithoutAdditivePageResultScreen';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';

function DilutionAdditionVolumePage(): React.ReactElement {
 const { currentScreen, setCurrentScreen, loadHistory, homeRedirect } =
  useContext(InfusionWithoutAdditiveContext);
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();
 const { setHomeButtonAction } = useContext(CalculatorNavigationContext);

 useEffect(() => {
  if (currentScreen && currentScreen < InfusionScreen.WithoutAdditive.First) {
   setCurrentScreen(InfusionScreen.WithoutAdditive.First);
  }
  setCheckboxValue(false);
  setFinalResultUnit(UnitType.Ml);
  if (state && state.data) {
   loadHistory(state.data);
  }
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === InfusionScreen.WithoutAdditive.First && (
    <InfusionWithoutAdditivePageFirstScreen />
   )}
   {currentScreen === InfusionScreen.WithoutAdditive.Second && (
    <InfusionWithoutAdditivePageSecondScreen />
   )}
   {currentScreen === InfusionScreen.WithoutAdditive.Result && (
    <InfusionWithoutAdditivePageResultScreen />
   )}
  </>
 );
}

export default DilutionAdditionVolumePage;
