// Injeksjon

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
 AppRoutes,
 CalculationHistory,
 InjectionScreen,
 MathOperation,
 ResultOperation,
 ResultRow,
 UnitType,
} from '../types';

import { InjectionContextType } from '../types/contexts';

import {
 getUnitTypeByString,
 getUnitOfMeasurementByUnitType,
 roundNumber,
 getValuesFromResultRows,
 getDescriptionsFromResultRows,
 getValuesPerWeightFromResultRows,
 getUnitsFromResultRows,
 convertUnit,
} from '../utils';
import { CalculatorContext } from './CalculatorProvider';

import { ResultsContext } from './ResultsProvider';

export const InjectionContext = createContext<InjectionContextType>({
 setCurrentScreen: () => null,
 setInjectionResultRows: () => null,
 injectionResultRows: [],
 onSelectChange: () => null,
 onValueChange: () => null,
 getValueInitialState: () => '',
 getSelectInitialState: () => '',
 selectedUnit: UnitType.Gram,
 setSelectedUnit: () => null,
 loadHistory: () => null,
 setCalculationHistory: () => null,
});

interface InjectionProviderProps {
 children: React.ReactElement;
}

export function InjectionProvider({
 children,
}: InjectionProviderProps): React.ReactElement {
 const { checkboxValue, setCheckboxValue } = useContext(CalculatorContext);

 const navigation = useNavigate();

 const [currentScreen, setCurrentScreen] = useState(-1);

 const [injectionResultRows, setInjectionResultRows] = useState<
  Array<ResultRow>
 >([
  { description: 'Dose' },
  { description: 'Pasientens vekt' },
  { description: 'Styrke pr ml' },
 ]);

 const [injectionOperationsResult, setInjectionOperationsResult] = useState<
  Array<ResultOperation>
 >([
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
 ]);

 const [injectionFinalResult, setInjectionFinalResult] = useState(0);
 const [selectedUnit, setSelectedUnit] = useState<string>('');

 const {
  setResultRows,
  setFinalResultValue,
  setResultOperations,
  setResultsCalculationHistory,
 } = useContext(ResultsContext);

 const [calculationHistory, setCalculationHistory] =
  useState<CalculationHistory>({
   title: 'Injeksjon',
   redirectTo: AppRoutes.Injection,
  });

 const saveHistory = () => {
  setCalculationHistory({
   ...calculationHistory,
   date: new Date(),
   data: {
    currentScreen: InjectionScreen.Result,
    resultRows: injectionResultRows,
    operationResult: injectionOperationsResult,
    checkboxValue,
   },
  });
 };

 const loadHistory = (data: any) => {
  if (data.currentScreen) {
   setCurrentScreen(data.currentScreen);
  }
  if (data.resultRows) {
   setInjectionResultRows(data.resultRows);
  }
  if (data.operationResult) {
   setInjectionOperationsResult(data.operationResult);
  }
  if (data.checkboxValue) {
   setCheckboxValue(data.checkboxValue);
  }
  if (data.index !== undefined) {
   setCalculationHistory({
    ...calculationHistory,
    index: data.index,
   });
  }
 };

 const homeRedirect = () => () => {
  setCalculationHistory({ ...calculationHistory, index: undefined });
  setInjectionResultRows([
   { description: 'Dose' },
   { description: 'Pasientens vekt' },
   { description: 'Styrke pr ml' },
  ]);
  setCurrentScreen(-1);
  navigation(AppRoutes.Home);
 };

 const firstScreenRedirect = () => () =>
  setCurrentScreen(InjectionScreen.First);
 const secondScreenRedirect = () => () =>
  setCurrentScreen(InjectionScreen.Second);
 const thirdScreenRedirect = () => () =>
  setCurrentScreen(InjectionScreen.Third);
 const resultsRedirect = () => () => setCurrentScreen(InjectionScreen.Result);

 const onSelectChange = (string: string) => {
  const unitType = getUnitTypeByString(string);
  const unitOfMeasurement = getUnitOfMeasurementByUnitType(unitType);
  setInjectionResultRows(
   injectionResultRows?.map((input, index) =>
    index === currentScreen
     ? {
        ...input,
        calculatorValue: { ...input.calculatorValue, unit: unitOfMeasurement },
       }
     : input,
   ),
  );
 };

 const onValueChange = (value: string) => {
  setInjectionResultRows(
   injectionResultRows?.map((input, index) =>
    index === currentScreen
     ? { ...input, calculatorValue: { ...input.calculatorValue, value } }
     : input,
   ),
  );
 };

 const getValueInitialState = () => {
  const value = injectionResultRows.find((_, index) => index === currentScreen)
   ?.calculatorValue?.value;
  return value ?? '';
 };

 const getSelectInitialState = () => {
  const value = injectionResultRows.find((_, index) => index === currentScreen)
   ?.calculatorValue?.unit?.description;
  return value ?? '';
 };

 const calculateResult = () => {
  const values = getValuesPerWeightFromResultRows(injectionResultRows);
  const newOperationsResult = [...injectionOperationsResult];
  if (!values[InjectionScreen.First] || !values[InjectionScreen.Third]) {
   return;
  }
  let partialResult = values[InjectionScreen.First];
  if (values[InjectionScreen.Second]) {
   partialResult =
    (values[InjectionScreen.First] * values[InjectionScreen.Second]) / 1000;
  }
  const result = roundNumber(partialResult / values[InjectionScreen.Third]);
  setInjectionFinalResult(result);
  newOperationsResult[2].resultValue = `${result} ${UnitType.Ml}`;
 };

 const calculateOperations = () => {
  const description = getDescriptionsFromResultRows(injectionResultRows);
  const values = getValuesFromResultRows(injectionResultRows);
  const units = getUnitsFromResultRows(injectionResultRows);
  const newOperationsResult = [...injectionOperationsResult];
  if (description[InjectionScreen.First]) {
   newOperationsResult[0].firstValue = `${
    description[InjectionScreen.First]
   }/kg`;
  }
  if (description[InjectionScreen.Second]) {
   newOperationsResult[0].secondValue = description[InjectionScreen.Second];
   const resultValue = convertUnit(
    values[InjectionScreen.First] * values[InjectionScreen.Second],
    units[InjectionScreen.First],
    units[InjectionScreen.Second],
   );
   newOperationsResult[0].resultValue = resultValue;
   newOperationsResult[1].firstValue = resultValue;
  } else {
   newOperationsResult[0].resultValue = '';
   newOperationsResult[1].firstValue = description[InjectionScreen.First];
  }
  if (description[InjectionScreen.Third]) {
   newOperationsResult[2].secondValue = description[InjectionScreen.Third];
  }
  newOperationsResult[1].firstValue = `${
   values[InjectionScreen.First] * values[InjectionScreen.Second]
  } ${units[InjectionScreen.First]?.description}`;
  const partialResult =
   (values[InjectionScreen.First] * values[InjectionScreen.Second]) / 1000;
  newOperationsResult[1].secondValue = '1000';
  newOperationsResult[1].resultValue = `${partialResult} mg`;
  newOperationsResult[2].firstValue = newOperationsResult[1].resultValue;
  setInjectionOperationsResult(newOperationsResult);
 };

 useEffect(() => {
  setFinalResultValue(injectionFinalResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [injectionFinalResult]);

 useEffect(() => {
  calculateResult();
  calculateOperations();
  setResultRows(injectionResultRows);
  saveHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [injectionResultRows]);

 useEffect(() => {
  setInjectionResultRows(
   injectionResultRows?.map((input, index) =>
    index > currentScreen ? { ...input, calculatorValue: undefined } : input,
   ),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [currentScreen]);

 useEffect(() => {
  setResultOperations(injectionOperationsResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [injectionOperationsResult]);

 useEffect(() => {
  setResultsCalculationHistory(calculationHistory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [calculationHistory]);

 return (
  <InjectionContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    currentScreen,
    setCurrentScreen,
    homeRedirect,
    firstScreenRedirect,
    secondScreenRedirect,
    thirdScreenRedirect,
    resultsRedirect,
    injectionResultRows,
    setInjectionResultRows,
    onSelectChange,
    onValueChange,
    getValueInitialState,
    getSelectInitialState,
    selectedUnit,
    setSelectedUnit,
    calculationHistory,
    setCalculationHistory,
    loadHistory,
   }}
  >
   {children}
  </InjectionContext.Provider>
 );
}
