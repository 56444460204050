import React, { createContext, useState } from 'react';
import { InputTimer, UnitOfMeasurement } from '../types';

import { CalculatorContextType } from '../types/contexts';

export const CalculatorContext = createContext<CalculatorContextType>({
 setHasCheckbox: () => null,
 setPageTitle: () => null,
 setQuestion: () => null,
 setCheckboxText: () => null,
 setUnitTypes: () => null,
 unitTypes: [],
 setCheckboxAction: () => null,
 setCheckboxValue: () => null,
 setInputValue: () => null,
 inputValue: '',
 checkboxValue: false,
 setShowInfo: () => null,
 showInfo: false,
 setTimerInputSelected: () => null,
 timerInputSelected: InputTimer.Timer,
 isTimer: false,
 setIsTimer: () => null,
 setInfoText: () => null,
});

interface CalculatorProviderProps {
 children: React.ReactElement;
}

export function CalculatorProvider({
 children,
}: CalculatorProviderProps): React.ReactElement {
 const [hasCheckbox, setHasCheckbox] = useState<boolean>(false);
 const [pageTitle, setPageTitle] = useState<string>('');
 const [question, setQuestion] = useState<string>('');
 const [checkboxText, setCheckboxText] = useState<string>('');
 const [unitTypes, setUnitTypes] = useState<Array<UnitOfMeasurement>>([]);
 const [checkboxAction, setCheckboxAction] = useState<any>(null);
 const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
 const [inputValue, setInputValue] = useState<string>('');
 const [showInfo, setShowInfo] = useState(false);
 const [infoText, setInfoText] = useState(
  'Følg lokal prosedyre for inkludering av skyllevolum.',
 );
 const [timerInputSelected, setTimerInputSelected] = useState(InputTimer.Timer);
 const [isTimer, setIsTimer] = useState(false);

 return (
  <CalculatorContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    hasCheckbox,
    setHasCheckbox,
    pageTitle,
    setPageTitle,
    question,
    setQuestion,
    checkboxText,
    setCheckboxText,
    unitTypes,
    setUnitTypes,
    checkboxAction,
    setCheckboxAction,
    checkboxValue,
    setCheckboxValue,
    inputValue,
    setInputValue,
    showInfo,
    setShowInfo,
    infoText,
    setInfoText,
    timerInputSelected,
    setTimerInputSelected,
    isTimer,
    setIsTimer,
   }}
  >
   {children}
  </CalculatorContext.Provider>
 );
}
