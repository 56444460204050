import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { DilutionScreen, UnitType } from '../../../types';

import { CalculatorContext } from '../../../contexts/CalculatorProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';
import { DilutionAdditionVolumeContext } from '../../../contexts/DilutionProvider/DilutionAdditionVolumeProvider';
import DilutionAdditionVolumeFirstScreen from './DilutionAdditionVolumeFirstScreen';
import DilutionAdditionVolumeSecondScreen from './DilutionAdditionVolumeSecondScreen';
import DilutionAdditionVolumeThirdScreen from './DilutionAdditionVolumeThirdScreen';
import DilutionAdditionVolumeResultScreen from './DilutionAdditionVolumeResultScreen';

function DilutionAdditionVolumePage(): React.ReactElement {
 const { currentScreen, setCurrentScreen, loadHistory } = useContext(
  DilutionAdditionVolumeContext,
 );
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();

 useEffect(() => {
  if (currentScreen && currentScreen < DilutionScreen.First) {
   setCurrentScreen(DilutionScreen.First);
  }
  setCheckboxValue(false);
  setFinalResultUnit(UnitType.Ml);
  if (state && state.data) {
   loadHistory(state.data);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === DilutionScreen.First && (
    <DilutionAdditionVolumeFirstScreen />
   )}
   {currentScreen === DilutionScreen.Second && (
    <DilutionAdditionVolumeSecondScreen />
   )}
   {currentScreen === DilutionScreen.Third && (
    <DilutionAdditionVolumeThirdScreen />
   )}
   {currentScreen === DilutionScreen.Result && (
    <DilutionAdditionVolumeResultScreen />
   )}
  </>
 );
}

export default DilutionAdditionVolumePage;
