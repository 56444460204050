import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CalculatorContext } from '../../../contexts/CalculatorProvider';

import LiquidDraperFirstScreen from './LiquidDraperFirstScreen';
import LiquidDraperSecondScreen from './LiquidDraperSecondScreen';
import LiquidDraperThirdScreen from './LiquidDraperThirdScreen';
import LiquidDraperResultScreen from './LiquidDraperResultScreen';
import LiquidDraperFourthScreen from './LiquidDraperFourthScreen';

import { LiquidRoutes, LiquidScreen, UnitType } from '../../../types';

import { ResultsContext } from '../../../contexts/ResultsProvider';
import { LiquidContext } from '../../../contexts/LiquidProvider';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';

function Pills(): React.ReactElement {
 const {
  currentScreen,
  setCurrentScreen,
  setCalculationHistory,
  loadHistory,
  homeRedirect,
 } = useContext(LiquidContext);
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();
 const { setHomeButtonAction } = useContext(CalculatorNavigationContext);

 useEffect(() => {
  if (currentScreen && currentScreen < LiquidScreen.First) {
   setCurrentScreen(LiquidScreen.First);
  }
  setCheckboxValue(false);
  setFinalResultUnit(UnitType.Draper);
  setHomeButtonAction(homeRedirect);
  setCalculationHistory({
   title: 'Flytende - Dråper',
   redirectTo: LiquidRoutes.Draper,
  });
  if (state && state.data) {
   loadHistory(state.data);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === LiquidScreen.First && <LiquidDraperFirstScreen />}
   {currentScreen === LiquidScreen.Second && <LiquidDraperSecondScreen />}
   {currentScreen === LiquidScreen.Third && <LiquidDraperThirdScreen />}
   {currentScreen === LiquidScreen.Fourth && <LiquidDraperFourthScreen />}
   {currentScreen === LiquidScreen.Result && <LiquidDraperResultScreen />}
  </>
 );
}

export default Pills;
