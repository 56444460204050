import DilutionHomePage from './DilutionHomePage';
import DilutionAdditionVolumePage from './DilutionAdditionVolumePage';
import DilutionStrengthDiluted from './DilutionStrengthDilutedPage';

const DilutionPage = {
 Home: DilutionHomePage,
 AdditionVolume: DilutionAdditionVolumePage,
 StrengthDiluted: DilutionStrengthDiluted,
};

export default DilutionPage;
