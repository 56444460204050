import React, { useState, useEffect, useContext } from 'react';
import { VStack, Text, HStack, Box, Flex } from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import CalculatorInputsComponent from './CalculatorInputsComponent';
import CalculatorKeyboardComponent from './CalculatorKeyboardComponent';

import { CalculatorOption, InputTimer } from '../../types';

import { charOccurrencesInString, isValidInputValueTimer } from '../../utils';

import { CalculatorNavigationContext } from '../../contexts/CalculatorNavigationProvider';
import { CalculatorContext } from '../../contexts/CalculatorProvider';

import QuestionComponent from '../QuestionComponent';
import CalculatorNavigationComponent from './CalculatorNavigationComponent';
import CalculatorTimerComponent from './CalculatorTimerComponent';
import Header from '../HeaderComponent';

export interface CalculatorComponentProps {
 valueInitialState: string;
 valueTimerInitialState?: Array<string>;
 selectInitialState: string;
 onValueChange?: (value: string) => void;
 onSelectChange?: (value: string) => void;
 onValueTimerChange?: (value: Array<string>) => void;
 selectPerTime?: boolean;
 hasComma?: boolean;
}

function CalculatorComponent({
 valueInitialState,
 valueTimerInitialState,
 selectInitialState,
 onValueChange,
 onSelectChange,
 onValueTimerChange,
 selectPerTime,
 hasComma,
}: CalculatorComponentProps): React.ReactElement {
 const DIGITS_LIMIT = 10;
 const [selectValue, setSelectValue] = useState<string>(selectInitialState);
 const [inputValue, setInputValue] = useState<string>(valueInitialState);
 const [inputValueTimer, setInputValueTimer] = useState<Array<string>>(
  valueTimerInitialState || ['', ''],
 );
 const { setNextButtonDisabled } = useContext(CalculatorNavigationContext);
 const {
  question,
  pageTitle,
  showInfo,
  infoText,
  isTimer,
  timerInputSelected,
  unitTypes,
 } = useContext(CalculatorContext);

 useEffect(() => {
  if (!isTimer) {
   setNextButtonDisabled(!(selectValue.length > 0 && inputValue));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [inputValue, selectValue, setNextButtonDisabled]);

 useEffect(() => {
  if (unitTypes.length === 1) {
   return setSelectValue(unitTypes[0].description);
  }

  return setSelectValue('Velg enhet');
 }, [unitTypes]);

 useEffect(() => {
  if (onValueChange) {
   onValueChange(inputValue);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [inputValue]);

 useEffect(() => {
  if (isTimer) {
   setNextButtonDisabled(!isValidInputValueTimer(inputValueTimer));
   if (onValueTimerChange) {
    onValueTimerChange(inputValueTimer);
   }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [inputValueTimer]);

 useEffect(() => {
  if (onSelectChange) {
   onSelectChange(selectValue);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [selectValue]);

 const isValidInputValue = (inputValueToTest: string): boolean => {
  if (inputValueToTest.length > DIGITS_LIMIT) {
   return false;
  }
  if (inputValueToTest.includes(CalculatorOption.Comma)) {
   if (!inputValue?.length) {
    return false;
   }
   if (charOccurrencesInString(CalculatorOption.Comma, inputValueToTest) > 1) {
    return false;
   }
  }
  return true;
 };

 const onHandleChangeInputValue = (value: string | Array<string>) => {
  if (typeof value === 'string') {
   if (isValidInputValue(value)) {
    if (!isTimer) {
     setInputValue(value);
    } else if (value.length < 3) {
     const position = timerInputSelected === InputTimer.Timer ? 0 : 1;
     setInputValueTimer(
      inputValueTimer.map((valueTimer, index) =>
       index === position ? value : valueTimer,
      ),
     );
    }
   }
  } else {
   const position = timerInputSelected === InputTimer.Timer ? 0 : 1;
   if (value[position].length < 3) {
    setInputValueTimer(value);
   }
  }
 };

 return (
  <VStack w="100%" flex={1} spacing={0}>
   <CalculatorNavigationComponent />
   <Flex w="100%" py="40px" px="30px">
    <Header />
   </Flex>
   <Text fontSize="16px" textAlign="center">
    {pageTitle}
   </Text>
   <QuestionComponent question={question} />
   {showInfo && (
    <HStack justifyContent="flex-start" px="30px" mb="10px !important">
     <Box>
      <AiOutlineInfoCircle size={20} />
     </Box>
     <Text mb={5} color="black.500" ml="10px" fontSize="18px">
      {infoText}
     </Text>
    </HStack>
   )}

   {!isTimer ? (
    <CalculatorInputsComponent
     inputValue={inputValue}
     onHandleChangeInputValue={onHandleChangeInputValue}
     selectValue={selectValue}
     selectPerTime={selectPerTime}
     setSelectValue={setSelectValue}
    />
   ) : (
    <CalculatorTimerComponent
     inputValue={inputValueTimer}
     onHandleChangeInputValue={onHandleChangeInputValue}
    />
   )}
   {!isTimer ? (
    <CalculatorKeyboardComponent
     inputValue={inputValue}
     onHandleChangeInputValue={onHandleChangeInputValue}
     hasComma={hasComma}
    />
   ) : (
    <CalculatorKeyboardComponent
     inputValue={
      timerInputSelected === InputTimer.Timer
       ? inputValueTimer[0]
       : inputValueTimer[1]
     }
     onHandleChangeInputValue={onHandleChangeInputValue}
     hasComma={false}
    />
   )}
  </VStack>
 );
}

CalculatorComponent.defaultProps = {
 checkboxText: '',
 hasCheckbox: false,
 valueInitialState: '',
 selectInitialState: '',
 selectPerTime: false,
 hasComma: true,
};

export default CalculatorComponent;
