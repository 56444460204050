// Infusion - Utel tilsetning

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
 AppRoutes,
 CalculationHistory,
 CalculatorOption,
 InfusionRoutes,
 InfusionScreen,
 MathOperation,
 ResultOperation,
 ResultRow,
 UnitType,
} from '../../../types';

import { InfusionWithoutAdditiveContextType } from '../../../types/contexts';

import {
 getUnitTypeByString,
 getUnitOfMeasurementByUnitType,
 roundNumber,
 getDescriptionsFromResultRows,
 getOnlyNumbers,
 getTimerValueFromString,
 formatTimerString,
} from '../../../utils';
import { CalculatorContext } from '../../CalculatorProvider';

import { ResultsContext } from '../../ResultsProvider';

export const InfusionWithoutAdditiveContext =
 createContext<InfusionWithoutAdditiveContextType>({
  setCurrentScreen: () => null,
  setInfusionWithoutAdditiveResultRows: () => null,
  infusionWithoutAdditiveResultRows: [],
  onSelectChange: () => null,
  onValueChange: () => null,
  onValueTimerChange: () => null,
  getValueInitialState: () => '',
  getValueTimerInitialState: () => ['', ''],
  getSelectInitialState: () => '',
  selectedUnit: UnitType.Gram,
  setSelectedUnit: () => null,
  loadHistory: () => null,
  setCalculationHistory: () => null,
 });

interface InfusionWithoutAdditiveProviderProps {
 children: React.ReactElement;
}

export function InfusionWithoutAdditiveProvider({
 children,
}: InfusionWithoutAdditiveProviderProps): React.ReactElement {
 const { setCheckboxValue, checkboxValue } = useContext(CalculatorContext);

 const navigation = useNavigate();

 const [currentScreen, setCurrentScreen] = useState(-1);

 const [
  infusionWithoutAdditiveResultRows,
  setInfusionWithoutAdditiveResultRows,
 ] = useState<Array<ResultRow>>([
  { description: 'Volum konsentrat' },
  { description: 'Varighet' },
 ]);

 const [
  infusionWithoutAdditiveOperationsResult,
  setInfusionWithoutAdditiveOperationsResult,
 ] = useState<Array<ResultOperation>>([
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
 ]);

 const [
  infusionWithoutAdditiveFinalResult,
  setInfusionWithoutAdditiveFinalResult,
 ] = useState<Array<ResultRow>>([
  { description: 'Hastighet' },
  { description: 'Hastighet' },
  { description: 'Hastighet' },
  { description: 'Hastighet' },
 ]);

 const [selectedUnit, setSelectedUnit] = useState<string>('');

 const {
  setResultRows,
  setResultArray,
  setResultOperations,
  setResultsCalculationHistory,
 } = useContext(ResultsContext);

 const [calculationHistory, setCalculationHistory] =
  useState<CalculationHistory>({
   title: 'Infusion - Ingen tilsetning',
   redirectTo: InfusionRoutes.WithoutAdditive,
  });

 const saveHistory = () => {
  setCalculationHistory({
   ...calculationHistory,
   date: new Date(),
   data: {
    currentScreen: InfusionScreen.WithoutAdditive.Result,
    resultRows: infusionWithoutAdditiveResultRows,
    operationResult: infusionWithoutAdditiveOperationsResult,
    checkboxValue,
   },
  });
 };

 const loadHistory = (data: any) => {
  if (data.currentScreen) {
   setCurrentScreen(data.currentScreen);
  }
  if (data.resultRows) {
   setInfusionWithoutAdditiveResultRows(data.resultRows);
  }
  if (data.operationResult) {
   setInfusionWithoutAdditiveOperationsResult(data.operationResult);
  }
  if (data.checkboxValue) {
   setCheckboxValue(data.checkboxValue);
  }
  if (data.index !== undefined) {
   setCalculationHistory({
    ...calculationHistory,
    index: data.index,
   });
  }
 };

 const homeRedirect = () => () => {
  setCalculationHistory({ ...calculationHistory, index: undefined });
  setInfusionWithoutAdditiveResultRows([
   { description: 'Volum konsentrat' },
   { description: 'Varighet' },
  ]);
  setCurrentScreen(-1);
  navigation(AppRoutes.Home);
 };

 const firstScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.WithoutAdditive.First);
 const secondScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.WithoutAdditive.Second);
 const resultsRedirect = () => () =>
  setCurrentScreen(InfusionScreen.WithoutAdditive.Result);

 const onSelectChange = (string: string) => {
  const unitType = getUnitTypeByString(string);
  const unitOfMeasurement = getUnitOfMeasurementByUnitType(unitType);
  const newinfusionWithoutAdditiveResultRows = [
   ...infusionWithoutAdditiveResultRows,
  ].map((input, index) =>
   index === currentScreen
    ? {
       ...input,
       calculatorValue: { ...input.calculatorValue, unit: unitOfMeasurement },
      }
    : input,
  );
  setInfusionWithoutAdditiveResultRows(newinfusionWithoutAdditiveResultRows);
 };

 const onValueChange = (value: string) => {
  const newinfusionWithoutAdditiveResultRows = [
   ...infusionWithoutAdditiveResultRows,
  ].map((input, index) =>
   index === currentScreen
    ? { ...input, calculatorValue: { ...input.calculatorValue, value } }
    : input,
  );
  setInfusionWithoutAdditiveResultRows(newinfusionWithoutAdditiveResultRows);
 };

 const onValueTimerChange = (value: Array<string>) => {
  const newinfusionWithoutAdditiveResultRows = [
   ...infusionWithoutAdditiveResultRows,
  ].map((input, index) =>
   index === InfusionScreen.WithoutAdditive.Second
    ? {
       ...input,
       calculatorValue: {
        ...input.calculatorValue,
        value: `${value[0].trim() === '' ? `0${value[0]}` : `${value[0]}`}
        ${UnitType.Timer}
        ${value[1] ? `, ${value[1]}${UnitType.Minutes}` : ''}`,
       },
      }
    : input,
  );
  setInfusionWithoutAdditiveResultRows(newinfusionWithoutAdditiveResultRows);
 };

 const getValueInitialState = () => {
  const value = infusionWithoutAdditiveResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.value;
  return value ?? '';
 };

 const getValueTimerInitialState = () => {
  const value =
   infusionWithoutAdditiveResultRows[InfusionScreen.WithoutAdditive.Second]
    .calculatorValue?.value;
  if (!value) {
   return ['', ''];
  }
  return value.split(CalculatorOption.Comma).map(v => getOnlyNumbers(v));
 };

 const getSelectInitialState = () => {
  const value = infusionWithoutAdditiveResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.unit?.description;
  return value ?? '';
 };

 const calculateResult = () => {
  const description = getDescriptionsFromResultRows(
   infusionWithoutAdditiveResultRows,
  );

  if (
   !description[InfusionScreen.WithoutAdditive.First] ||
   !description[InfusionScreen.WithoutAdditive.Second]
  ) {
   return;
  }

  const firstValue = parseInt(
   getOnlyNumbers(description[InfusionScreen.WithoutAdditive.First]),
   10,
  );
  const secondValue = getTimerValueFromString(
   description[InfusionScreen.WithoutAdditive.Second],
  );

  const drapperTotal = `${firstValue * 20} ${UnitType.Draper}`;

  const firstPartialResult = firstValue / secondValue;
  const firstFormattedPartialResult = `${roundNumber(firstPartialResult)} ${
   UnitType.Ml
  }/${UnitType.Minutes}`;

  const secondPartialResult = firstPartialResult * 60;
  const secondFormattedPartialResult = `${roundNumber(secondPartialResult)} ${
   UnitType.Ml
  }${MathOperation.division}${UnitType.Timer}`;

  const thirdPartialResult = firstPartialResult * 20;
  const thirdFormattedPartialResult = `${roundNumber(thirdPartialResult)} ${
   UnitType.Draper
  }${MathOperation.division}${UnitType.Minutes}`;

  const fourthPartialResult = thirdPartialResult / 60;
  const fourthFormattedPartialResult = `${roundNumber(fourthPartialResult)} ${
   UnitType.Draper
  }${MathOperation.division}sek`;

  const newOperationsResult: ResultOperation[] = [
   {
    firstValue: description[0],
    operation: MathOperation.division,
    secondValue: formatTimerString(description[1]),
    resultValue: secondFormattedPartialResult,
    highlighted: true,
   },
   {
    firstValue: description[0],
    operation: MathOperation.division,
    secondValue: `${secondValue} min`,
    resultValue: firstFormattedPartialResult,
    highlighted: true,
   },
   {
    firstValue: description[0],
    operation: MathOperation.times,
    secondValue: `20 ${UnitType.Draper}/ml`,
    resultValue: drapperTotal,
    highlighted: true,
   },
   {
    firstValue: drapperTotal,
    operation: MathOperation.division,
    secondValue: `${secondValue} min`,
    resultValue: thirdFormattedPartialResult,
    highlighted: true,
   },
   {
    firstValue: drapperTotal,
    operation: MathOperation.division,
    secondValue: `${secondValue * 60} sek`,
    resultValue: fourthFormattedPartialResult,
    highlighted: true,
   },
  ];

  const finalResult = [...infusionWithoutAdditiveFinalResult];
  finalResult[0].calculatorValue = {
   ...finalResult[0].calculatorValue,
   value: secondFormattedPartialResult,
  };
  finalResult[1].calculatorValue = {
   ...finalResult[1].calculatorValue,
   value: firstFormattedPartialResult,
  };
  finalResult[2].calculatorValue = {
   ...finalResult[2].calculatorValue,
   value: thirdFormattedPartialResult,
  };
  finalResult[3].calculatorValue = {
   ...finalResult[3].calculatorValue,
   value: fourthFormattedPartialResult,
  };

  setInfusionWithoutAdditiveFinalResult(finalResult);
  setInfusionWithoutAdditiveOperationsResult(newOperationsResult);
 };

 useEffect(() => {
  setResultArray(infusionWithoutAdditiveFinalResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionWithoutAdditiveFinalResult]);

 useEffect(() => {
  calculateResult();
  setResultRows(infusionWithoutAdditiveResultRows);
  saveHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionWithoutAdditiveResultRows]);

 useEffect(() => {
  setResultOperations(infusionWithoutAdditiveOperationsResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionWithoutAdditiveOperationsResult]);

 useEffect(() => {
  setInfusionWithoutAdditiveResultRows(
   infusionWithoutAdditiveResultRows?.map((input, index) =>
    index > currentScreen ? { ...input, calculatorValue: undefined } : input,
   ),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [currentScreen]);

 useEffect(() => {
  setResultsCalculationHistory(calculationHistory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [calculationHistory]);

 return (
  <InfusionWithoutAdditiveContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    currentScreen,
    setCurrentScreen,
    homeRedirect,
    firstScreenRedirect,
    secondScreenRedirect,
    resultsRedirect,
    infusionWithoutAdditiveResultRows,
    setInfusionWithoutAdditiveResultRows,
    onSelectChange,
    onValueChange,
    onValueTimerChange,
    getValueInitialState,
    getValueTimerInitialState,
    getSelectInitialState,
    selectedUnit,
    setSelectedUnit,
    calculationHistory,
    setCalculationHistory,
    loadHistory,
   }}
  >
   {children}
  </InfusionWithoutAdditiveContext.Provider>
 );
}
