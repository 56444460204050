import InfusionHomePage from './InfusionHomePage';
import InfusionAddedDrugPage from './InfusionAddedDrugPage';
import InfusionWithoutAdditivePage from './InfusionWithoutAdditivePage';
import InfusionPerTimePage from './InfusionPerTimePage';

const InfusionPage = {
 Home: InfusionHomePage,
 AddedDrug: InfusionAddedDrugPage,
 WithoutAdditive: InfusionWithoutAdditivePage,
 PerTime: InfusionPerTimePage,
};

export default InfusionPage;
