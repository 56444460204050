import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CalculatorContext } from '../../contexts/CalculatorProvider';
import { PillsContext } from '../../contexts/PillsProvider';
import { ResultsContext } from '../../contexts/ResultsProvider';
import { PillsScreen, UnitType } from '../../types';

import PillsFirstScreen from './PillsFirstScreen';
import PillsResultScreen from './PillsResultScreen';
import PillsSecondScreen from './PillsSecondScreen';
import PillsThirdScreen from './PillsThirdScreen';
import { CalculatorNavigationContext } from '../../contexts/CalculatorNavigationProvider';

function Pills(): React.ReactElement {
 const { currentScreen, setCurrentScreen, loadHistory, homeRedirect } =
  useContext(PillsContext);
 const { setHomeButtonAction } = useContext(CalculatorNavigationContext);
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();

 useEffect(() => {
  if (currentScreen && currentScreen < PillsScreen.First) {
   setCurrentScreen(PillsScreen.First);
  }
  setCheckboxValue(false);
  if (state && state.data) {
   loadHistory(state.data);
  }
  setFinalResultUnit(UnitType.Tablett);
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === PillsScreen.First && <PillsFirstScreen />}
   {currentScreen === PillsScreen.Second && <PillsSecondScreen />}
   {currentScreen === PillsScreen.Third && <PillsThirdScreen />}
   {currentScreen === PillsScreen.Result && <PillsResultScreen />}
  </>
 );
}

export default Pills;
