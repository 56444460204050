import React, { useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
 AppRoutes,
 GramUnitsPerMl,
 IEUnits,
 MolUnitsPerMl,
 UnitType,
} from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';
import { DilutionAdditionVolumeContext } from '../../../../contexts/DilutionProvider/DilutionAdditionVolumeProvider';

function DilutionAdditionVolumeFirstScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setNextButtonAction,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const {
  secondScreenRedirect,
  onSelectChange,
  onValueChange,
  getValueInitialState,
  getSelectInitialState,
  setSelectedUnit,
  setCurrentScreen,
 } = useContext(DilutionAdditionVolumeContext);

 const { setHasCheckbox, setPageTitle, setQuestion, setUnitTypes } =
  useContext(CalculatorContext);

 const unitTypes = [
  ...GramUnitsPerMl.filter(
   unit =>
    ![UnitType.Kilogram, UnitType.KilogramPerMl].includes(unit.description),
  ),
  ...MolUnitsPerMl,
  ...IEUnits,
 ];

 const navigate = useNavigate();

 const homeRedirect = () => () => {
  setCurrentScreen(-1);
  navigate(AppRoutes.Dilution);
 };

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(homeRedirect);
  };
  const setNextButtonProps = () => {
   setNextButtonText('Neste');
   setNextButtonAction(secondScreenRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };

 const setCalculatorProps = () => {
  setHasCheckbox(false);
  setPageTitle('Tilsetningsvolum');
  setQuestion('Hvilken styrke har det konsentrerte legemiddelet pr ml?');
  setUnitTypes(unitTypes);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <CalculatorComponent
   onValueChange={onValueChange}
   onSelectChange={value => {
    onSelectChange(value);
    setSelectedUnit(value);
   }}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default DilutionAdditionVolumeFirstScreen;
