// Fortynning - Tilsetningsvolum
/* eslint-disable no-console */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
 AppRoutes,
 CalculationHistory,
 CalculatorValue,
 DilutionRoutes,
 DilutionScreen,
 MathOperation,
 ResultOperation,
 ResultRow,
 UnitType,
} from '../../../types';

import { DilutionAdditionVolumeContextType } from '../../../types/contexts';

import {
 getUnitTypeByString,
 getUnitOfMeasurementByUnitType,
 roundNumber,
 getValuesFromResultRows,
 getDescriptionsFromResultRows,
 convertUnit,
 getUnitsFromResultRows,
 getValuesPerWeightFromResultRows,
} from '../../../utils';

import { ResultsContext } from '../../ResultsProvider';

export const DilutionAdditionVolumeContext =
 createContext<DilutionAdditionVolumeContextType>({
  setCurrentScreen: () => null,
  setDilutionAdditionVolumeResultRows: () => null,
  dilutionAdditionVolumeResultRows: [],
  onSelectChange: () => null,
  onValueChange: () => null,
  getValueInitialState: () => '',
  getSelectInitialState: () => '',
  selectedUnit: UnitType.Gram,
  setSelectedUnit: () => null,
  loadHistory: () => null,
  setCalculationHistory: () => null,
 });

interface DilutionAdditionVolumeProviderProps {
 children: React.ReactElement;
}

export function DilutionAdditionVolumeProvider({
 children,
}: DilutionAdditionVolumeProviderProps): React.ReactElement {
 const navigation = useNavigate();

 const [currentScreen, setCurrentScreen] = useState(-1);

 const [dilutionAdditionVolumeResultRows, setDilutionAdditionVolumeResultRows] =
  useState<Array<ResultRow>>([
   { description: 'Styrke, konsentrat' },
   { description: 'Volum konsentrat' },
   { description: 'Styrke, fortynning' },
   { description: 'Totalvolum, fortynning' },
  ]);
 const [
  dilutionAdditionVolumeOperationsResult,
  setDilutionAdditionVolumeOperationsResult,
 ] = useState<Array<ResultOperation>>([
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.minus, secondValue: '' },
 ]);
 const [
  dilutionAdditionVolumeFinalResult,
  setDilutionAdditionVolumeFinalResult,
 ] = useState(0);
 const [selectedUnit, setSelectedUnit] = useState<string>('');

 const {
  setResultRows,
  setFinalResultValue,
  setResultOperations,
  setResultsCalculationHistory,
 } = useContext(ResultsContext);

 const [calculationHistory, setCalculationHistory] =
  useState<CalculationHistory>({
   title: 'Tilsetningsvolum',
   redirectTo: DilutionRoutes.AdditionVolume,
  });

 const saveHistory = () => {
  setCalculationHistory({
   ...calculationHistory,
   date: new Date(),
   data: {
    currentScreen: DilutionScreen.Result,
    resultRows: dilutionAdditionVolumeResultRows,
    operationResult: dilutionAdditionVolumeOperationsResult,
   },
  });
 };

 const loadHistory = (data: any) => {
  if (data.currentScreen) {
   setCurrentScreen(data.currentScreen);
  }
  if (data.resultRows) {
   setDilutionAdditionVolumeResultRows(data.resultRows);
  }
  if (data.operationResult) {
   setDilutionAdditionVolumeOperationsResult(data.operationResult);
  }
  if (data.index !== undefined) {
   setCalculationHistory({
    ...calculationHistory,
    index: data.index,
   });
  }
 };

 const getValue = (item: CalculatorValue | undefined) => {
  return (item && item.value && Number(item.value)) || 1;
 };

 const calculateTotalVolume = (resultRow: ResultRow[]) => {
  return (
   (getValue(resultRow[DilutionScreen.First].calculatorValue) *
    getValue(resultRow[DilutionScreen.Second].calculatorValue)) /
   getValue(resultRow[DilutionScreen.Third].calculatorValue)
  );
 };

 const homeRedirect = () => () => {
  setCalculationHistory({ ...calculationHistory, index: undefined });
  setDilutionAdditionVolumeResultRows([
   { description: 'Styrke, konsentrat' },
   { description: 'Volum konsentrat' },
   { description: 'Styrke, fortynning' },
   { description: 'Totalvolum, fortynning' },
  ]);
  setCurrentScreen(-1);
  navigation(AppRoutes.Home);
 };

 const firstScreenRedirect = () => () => setCurrentScreen(DilutionScreen.First);
 const secondScreenRedirect = () => () =>
  setCurrentScreen(DilutionScreen.Second);
 const thirdScreenRedirect = () => () => setCurrentScreen(DilutionScreen.Third);
 const resultsRedirect = () => () => setCurrentScreen(DilutionScreen.Result);

 const onSelectChange = (string: string) => {
  const unitType = getUnitTypeByString(string);
  const unitOfMeasurement = getUnitOfMeasurementByUnitType(unitType);
  const newDilutionAdditionVolumeResultRows = [
   ...dilutionAdditionVolumeResultRows,
  ].map((input, index) =>
   index === currentScreen
    ? {
       ...input,
       calculatorValue: { ...input.calculatorValue, unit: unitOfMeasurement },
      }
    : input,
  );
  if (currentScreen === DilutionScreen.Third) {
   const totalVolume = calculateTotalVolume(
    newDilutionAdditionVolumeResultRows,
   );
   const formattedTotalVolume = `${roundNumber(totalVolume)}`;
   newDilutionAdditionVolumeResultRows[
    DilutionScreen.Third + 1
   ].calculatorValue = {
    ...newDilutionAdditionVolumeResultRows[DilutionScreen.Second]
     .calculatorValue,
    value: formattedTotalVolume,
   };
  }
  setDilutionAdditionVolumeResultRows(newDilutionAdditionVolumeResultRows);
 };

 const onValueChange = (value: string) => {
  const newDilutionAdditionVolumeResultRows = [
   ...dilutionAdditionVolumeResultRows,
  ].map((input, index) =>
   index === currentScreen
    ? { ...input, calculatorValue: { ...input.calculatorValue, value } }
    : input,
  );
  if (currentScreen === DilutionScreen.Third) {
   const totalVolume = calculateTotalVolume(
    newDilutionAdditionVolumeResultRows,
   );
   const formattedTotalVolume = `${roundNumber(totalVolume)}`;
   newDilutionAdditionVolumeResultRows[
    DilutionScreen.Third + 1
   ].calculatorValue = {
    ...newDilutionAdditionVolumeResultRows[DilutionScreen.Second]
     .calculatorValue,
    value: formattedTotalVolume,
   };
  }
  setDilutionAdditionVolumeResultRows(newDilutionAdditionVolumeResultRows);
 };

 const getValueInitialState = () => {
  const value = dilutionAdditionVolumeResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.value;
  return value ?? '';
 };

 const getSelectInitialState = () => {
  const value = dilutionAdditionVolumeResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.unit?.description;
  return value ?? '';
 };

 const calculateResult = () => {
  const values = getValuesPerWeightFromResultRows(
   dilutionAdditionVolumeResultRows,
  );
  const newOperationsResult = [...dilutionAdditionVolumeOperationsResult];
  if (
   !values[DilutionScreen.First] ||
   !values[DilutionScreen.Second] ||
   !values[DilutionScreen.Third]
  ) {
   return;
  }
  let partialResult =
   values[DilutionScreen.First] * values[DilutionScreen.Second];
  newOperationsResult[0].resultValue = `${roundNumber(partialResult)}`;
  newOperationsResult[2].firstValue = `${roundNumber(partialResult)}`;
  partialResult /= values[2];
  newOperationsResult[1].resultValue = `${roundNumber(partialResult)} ${
   UnitType.Ml
  }`;
  newOperationsResult[3].firstValue = `${roundNumber(partialResult)} ${
   UnitType.Ml
  }`;
  const result = roundNumber(partialResult - values[1]);
  setDilutionAdditionVolumeFinalResult(result);
  newOperationsResult[3].resultValue = `${result} ${UnitType.Ml}`;
  setDilutionAdditionVolumeOperationsResult(newOperationsResult);
 };

 const calculateOperations = () => {
  const description = getDescriptionsFromResultRows(
   dilutionAdditionVolumeResultRows,
  );
  const values = getValuesFromResultRows(dilutionAdditionVolumeResultRows);
  const units = getUnitsFromResultRows(dilutionAdditionVolumeResultRows);
  const newOperationsResult = [...dilutionAdditionVolumeOperationsResult];
  if (description[DilutionScreen.First]) {
   newOperationsResult[0].firstValue = description[DilutionScreen.First];
  }
  if (description[DilutionScreen.Second]) {
   newOperationsResult[0].secondValue = description[DilutionScreen.Second];
   newOperationsResult[3].secondValue = description[DilutionScreen.Second];
   let resultValue = convertUnit(
    values[DilutionScreen.First] * values[DilutionScreen.Second],
    units[DilutionScreen.First],
    units[DilutionScreen.Second],
   );
   // eslint-disable-next-line prefer-destructuring
   resultValue = resultValue.split('/')[0];
   newOperationsResult[0].resultValue = resultValue;
   newOperationsResult[1].firstValue = resultValue;
  } else {
   newOperationsResult[1].firstValue = description[DilutionScreen.First];
  }
  if (description[DilutionScreen.Third]) {
   newOperationsResult[1].secondValue = description[DilutionScreen.Third];
  }
 };

 useEffect(() => {
  setFinalResultValue(dilutionAdditionVolumeFinalResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dilutionAdditionVolumeFinalResult]);

 useEffect(() => {
  calculateResult();
  calculateOperations();
  setResultRows(dilutionAdditionVolumeResultRows);
  saveHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dilutionAdditionVolumeResultRows]);

 useEffect(() => {
  setResultOperations(dilutionAdditionVolumeOperationsResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dilutionAdditionVolumeOperationsResult]);

 useEffect(() => {
  setDilutionAdditionVolumeResultRows(
   dilutionAdditionVolumeResultRows?.map((input, index) =>
    index > currentScreen ? { ...input, calculatorValue: undefined } : input,
   ),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [currentScreen]);

 useEffect(() => {
  setResultsCalculationHistory(calculationHistory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [calculationHistory]);

 return (
  <DilutionAdditionVolumeContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    currentScreen,
    setCurrentScreen,
    homeRedirect,
    firstScreenRedirect,
    secondScreenRedirect,
    thirdScreenRedirect,
    resultsRedirect,
    dilutionAdditionVolumeResultRows,
    setDilutionAdditionVolumeResultRows,
    onSelectChange,
    onValueChange,
    getValueInitialState,
    getSelectInitialState,
    selectedUnit,
    setSelectedUnit,
    calculationHistory,
    setCalculationHistory,
    loadHistory,
   }}
  >
   {children}
  </DilutionAdditionVolumeContext.Provider>
 );
}
