import React, { useContext, useEffect, useState } from 'react';

import {
 GramUnits,
 GramUnitsPerMl,
 IEUnitsPerMl,
 MolUnitsPerMl,
 UnitType,
} from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { InfusionPerTimeContext } from '../../../../contexts/InfusionProvider/InfusionPerTimeProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';

import { getUnitTypeByString } from '../../../../utils';

function InfusionPerTimeThirdScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setBackButtonAction,
  setNextButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { setQuestion, setUnitTypes, checkboxValue, setHasCheckbox } =
  useContext(CalculatorContext);

 const {
  firstScreenRedirect,
  secondScreenRedirect,
  resultsRedirect,
  onValueChange,
  onSelectChange,
  getSelectInitialState,
  getValueInitialState,
  selectedUnit,
 } = useContext(InfusionPerTimeContext);

 const gramOptions = [UnitType.Microgram, UnitType.Miligram, UnitType.Gram];

 const molOptions = [UnitType.Micromol, UnitType.Milimol, UnitType.Mol];

 const [localUnitTypes, setLocalUnitTypes] = useState(GramUnits);

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
  };
  const setNextButtonProps = () => {
   setNextButtonText('Regn ut');
   setNextButtonAction(resultsRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };
 const setCalculatorProps = () => {
  setQuestion('Hvilken styrke har infusjonsløsningen per ml?');
  setUnitTypes(localUnitTypes);
  setHasCheckbox(false);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  if (checkboxValue) {
   setBackButtonAction(secondScreenRedirect);
  } else {
   setBackButtonAction(firstScreenRedirect);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [checkboxValue]);

 useEffect(() => {
  const formattedSelectedUnit = selectedUnit.split('/')[0];
  if (gramOptions.includes(getUnitTypeByString(formattedSelectedUnit))) {
   setLocalUnitTypes(
    GramUnitsPerMl.filter(
     unit =>
      ![UnitType.Kilogram, UnitType.KilogramPerMl].includes(unit.description),
    ),
   );
  } else if (molOptions.includes(getUnitTypeByString(formattedSelectedUnit))) {
   setLocalUnitTypes(MolUnitsPerMl);
  } else {
   setLocalUnitTypes(IEUnitsPerMl);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  setUnitTypes(localUnitTypes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [localUnitTypes]);

 return (
  <CalculatorComponent
   onValueChange={(value: string) => onValueChange(value)}
   onSelectChange={onSelectChange}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default InfusionPerTimeThirdScreen;
