import React, { useContext, useState } from 'react';
import { Button, VStack, Text, Flex, HStack } from '@chakra-ui/react';

import { FaHistory } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { CalculationHistory } from '../../types';
import { CalculatorContext } from '../../contexts/CalculatorProvider';
import { CalculatorNavigationContext } from '../../contexts/CalculatorNavigationProvider';

function FooterComponent(): React.ReactElement | null {
 const [isOpen, setIsOpen] = useState(false);
 const [history, setHistory] = useState<Array<CalculationHistory>>([]);

 const navigate = useNavigate();

 const { setPageTitle } = useContext(CalculatorContext);
 const { setNextButtonDisabled } = useContext(CalculatorNavigationContext);

 const getHistory = () => {
  const currentHistory = localStorage.getItem('calculation-history');
  if (currentHistory) {
   setHistory(JSON.parse(currentHistory));
  }
 };

 const openModal = () => {
  setIsOpen(!isOpen);
  getHistory();
 };

 const closeModal = () => {
  setIsOpen(false);
 };

 const onClickOption = (currentHistory: CalculationHistory, index: number) => {
  const { redirectTo, data } = currentHistory;
  setPageTitle(currentHistory.title);
  setNextButtonDisabled(false);
  if (redirectTo) {
   navigate(redirectTo, {
    state: {
     data: { ...data, index },
    },
   });
  }
 };

 const formatDate = (stringDate: Date | undefined) => {
  let formattedDate = '';
  if (stringDate) {
   const date = new Date(stringDate);
   const day = date.getDate();
   const month = date.toLocaleString('default', {
    month: 'short',
   });
   const hour = date.getHours();
   const minutes = `${date.getMinutes()}`.padStart(2, '0');
   formattedDate = `${day}. ${month} ${hour}:${minutes}`;
  }
  return formattedDate;
 };

 return (
  <>
   <Modal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={{
     overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 2,
     },
     content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '500px',
     },
    }}
   >
    <Flex justifyContent="space-between" mb="30px">
     <Text fontSize="28px">Siste utregninger</Text>
     <Text fontSize="28px" />
     <Button
      onClick={closeModal}
      p={0}
      bg="white"
      h="auto"
      minWidth={0}
      _hover={{ backgroundColor: 'white' }}
     >
      <GrClose size={32} />
     </Button>
    </Flex>
    {history.map((row, index) => (
     <HStack
      w="100%"
      alignItems="flex-start"
      borderBottom="1px solid"
      borderColor="gray.500"
      key={JSON.stringify(row)}
      spacing="30px"
      onClick={() => onClickOption(row, index)}
     >
      <VStack w="100%" alignItems="flex-start">
       <Text fontSize="20px">{row.title}</Text>
       <Text fontWeight="700" color="blackAlpha.500" fontSize="16px">
        {formatDate(row.date)}
       </Text>
      </VStack>
     </HStack>
    ))}
   </Modal>
   <VStack alignContent="center" justifyContent="center" w="100%" px="30px">
    <Button w="100%" fontSize="20px" onClick={openModal}>
     <FaHistory />
     <Text ml="10px">Siste utregninger</Text>
    </Button>
   </VStack>
  </>
 );
}

export default FooterComponent;
