import React, { useEffect, useContext } from 'react';

import ResultsComponent from '../../../components/ResultsComponent';

import { InjectionContext } from '../../../contexts/InjectionProvider';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';

function InjectionResultScreen(): React.ReactElement {
 const {
  setNextButtonAction,
  setNextButtonText,
  setBackButtonText,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { thirdScreenRedirect, homeRedirect } = useContext(InjectionContext);

 const { setHasMultipleResults } = useContext(ResultsContext);

 const setNavigationProps = () => {
  const setNextButtonProps = () => {
   setNextButtonAction(homeRedirect); // fix
   setNextButtonText('Ferdig');
  };
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(thirdScreenRedirect);
  };
  setNextButtonProps();
  setBackButtonProps();
 };

 useEffect(() => {
  setNavigationProps();
  setHasMultipleResults(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return <ResultsComponent />;
}

export default InjectionResultScreen;
