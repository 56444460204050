// Infusion - Utel tilsetning

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
 AppRoutes,
 CalculationHistory,
 InfusionRoutes,
 InfusionScreen,
 MathOperation,
 ResultOperation,
 ResultRow,
 UnitOfMeasurement,
 UnitType,
} from '../../../types';

import { InfusionPerTimeContextType } from '../../../types/contexts';

import {
 getUnitTypeByString,
 getUnitOfMeasurementByUnitType,
 roundNumber,
 getDescriptionsFromResultRows,
 getUnitsFromResultRows,
 getValuesFromResultRows,
} from '../../../utils';
import { CalculatorContext } from '../../CalculatorProvider';

import { ResultsContext } from '../../ResultsProvider';

export const InfusionPerTimeContext = createContext<InfusionPerTimeContextType>(
 {
  setCurrentScreen: () => null,
  setInfusionPerTimeResultRows: () => null,
  infusionPerTimeResultRows: [],
  onSelectChange: () => null,
  onValueChange: () => null,
  getValueInitialState: () => '',
  getSelectInitialState: () => '',
  selectedUnit: UnitType.Gram,
  setSelectedUnit: () => null,
  loadHistory: () => null,
  setCalculationHistory: () => null,
 },
);

interface InfusionPerTimeProviderProps {
 children: React.ReactElement;
}

export function InfusionPerTimeProvider({
 children,
}: InfusionPerTimeProviderProps): React.ReactElement {
 const { setCheckboxValue, checkboxValue } = useContext(CalculatorContext);

 const navigation = useNavigate();

 const [currentScreen, setCurrentScreen] = useState(-1);

 const [infusionPerTimeResultRows, setInfusionPerTimeResultRows] = useState<
  Array<ResultRow>
 >([
  { description: 'Dose' },
  { description: 'Pasientens vekt' },
  { description: 'Styrke, konsentrat' },
 ]);

 const [infusionPerTimeOperationsResult, setInfusionPerTimeOperationsResult] =
  useState<Array<ResultOperation>>([
   { firstValue: '', operation: MathOperation.division, secondValue: '' },
   { firstValue: '', operation: MathOperation.times, secondValue: '' },
   { firstValue: '', operation: MathOperation.times, secondValue: '' },
   { firstValue: '', operation: MathOperation.division, secondValue: '' },
  ]);

 const [infusionPerTimeFinalResult, setInfusionPerTimeFinalResult] = useState<
  Array<ResultRow>
 >([
  { description: 'Hastighet' },
  { description: 'Hastighet' },
  { description: 'Hastighet' },
  { description: 'Hastighet' },
 ]);

 const [selectedUnit, setSelectedUnit] = useState<string>('');

 const {
  setResultRows,
  setResultArray,
  setResultOperations,
  setResultsCalculationHistory,
 } = useContext(ResultsContext);

 const [calculationHistory, setCalculationHistory] =
  useState<CalculationHistory>({
   title: 'Infusion - Finne hastighet',
   redirectTo: InfusionRoutes.PerTime,
  });

 const saveHistory = () => {
  setCalculationHistory({
   ...calculationHistory,
   date: new Date(),
   data: {
    currentScreen: InfusionScreen.PerTime.Result,
    resultRows: infusionPerTimeResultRows,
    operationResult: infusionPerTimeOperationsResult,
    checkboxValue,
   },
  });
 };

 const loadHistory = (data: any) => {
  if (data.currentScreen) {
   setCurrentScreen(data.currentScreen);
  }
  if (data.resultRows) {
   setInfusionPerTimeResultRows(data.resultRows);
  }
  if (data.operationResult) {
   setInfusionPerTimeOperationsResult(data.operationResult);
  }
  if (data.checkboxValue) {
   setCheckboxValue(data.checkboxValue);
  }
  if (data.index !== undefined) {
   setCalculationHistory({
    ...calculationHistory,
    index: data.index,
   });
  }
 };

 const homeRedirect = () => () => {
  setCalculationHistory({ ...calculationHistory, index: undefined });
  setInfusionPerTimeResultRows([
   { description: 'Dose' },
   { description: 'Pasientens vekt' },
   { description: 'Styrke, konsentrat' },
  ]);
  setCurrentScreen(-1);
  navigation(AppRoutes.Home);
 };

 const firstScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.PerTime.First);
 const secondScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.PerTime.Second);
 const thirdScreenRedirect = () => () =>
  setCurrentScreen(InfusionScreen.PerTime.Third);
 const resultsRedirect = () => () =>
  setCurrentScreen(InfusionScreen.PerTime.Result);

 const onSelectChange = (string: string) => {
  const unitType = getUnitTypeByString(string);
  const unitOfMeasurement = getUnitOfMeasurementByUnitType(unitType);
  const newinfusionPerTimeResultRows = [...infusionPerTimeResultRows].map(
   (input, index) =>
    index === currentScreen
     ? {
        ...input,
        calculatorValue: { ...input.calculatorValue, unit: unitOfMeasurement },
       }
     : input,
  );
  setInfusionPerTimeResultRows(newinfusionPerTimeResultRows);
 };

 const onValueChange = (value: string) => {
  const newinfusionPerTimeResultRows = [...infusionPerTimeResultRows].map(
   (input, index) =>
    index === currentScreen
     ? { ...input, calculatorValue: { ...input.calculatorValue, value } }
     : input,
  );
  setInfusionPerTimeResultRows(newinfusionPerTimeResultRows);
 };

 const getValueInitialState = () => {
  const value = infusionPerTimeResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.value;
  return value ?? '';
 };

 const getSelectInitialState = () => {
  const value = infusionPerTimeResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.unit?.description;
  return value ?? '';
 };

 const convertBetweenUnits = (
  unit1: UnitOfMeasurement | undefined,
  unit2: UnitOfMeasurement | undefined,
  value: number,
 ): number => {
  if (!unit1 || !unit2) {
   return 0;
  }
  const factor = unit2.weight / unit1.weight;
  return roundNumber(value / factor);
 };

 const calculateResult = () => {
  const values = getValuesFromResultRows(infusionPerTimeResultRows);
  const description = getDescriptionsFromResultRows(infusionPerTimeResultRows);
  const units = getUnitsFromResultRows(infusionPerTimeResultRows);

  if (
   !description[InfusionScreen.PerTime.First] ||
   !description[InfusionScreen.PerTime.Third]
  ) {
   return;
  }

  const firstRowUnitSplitted =
   units[InfusionScreen.PerTime.First]?.description.split(
    MathOperation.division,
   ) || [];

  let secondRowFirstValue = values[InfusionScreen.PerTime.First];
  let alternativeSecondRowFirstValue = secondRowFirstValue;
  if (firstRowUnitSplitted[1] === UnitType.Minutes) {
   secondRowFirstValue *= 60;
  }

  let hideKg = true;
  if (values[InfusionScreen.PerTime.Second]) {
   secondRowFirstValue *= values[InfusionScreen.PerTime.Second];
   alternativeSecondRowFirstValue *= values[InfusionScreen.PerTime.Second];
   hideKg = false;
  }

  const unitSecondRow =
   description[InfusionScreen.PerTime.First].split('/')[1] === 'min'
    ? UnitType.Minutes
    : UnitType.Timer;
  const secondRowFirstValueFormatted = `${secondRowFirstValue} ${firstRowUnitSplitted[0]}${MathOperation.division}${UnitType.Timer}`;
  const alternativeSecondRowFirstValueFormatted = `${alternativeSecondRowFirstValue} ${firstRowUnitSplitted[0]}${MathOperation.division}${unitSecondRow}`;

  let timerResultFormatted;
  let timerOperation;
  let timerSecondValueFormatted;
  if (firstRowUnitSplitted[1] === UnitType.Minutes) {
   timerSecondValueFormatted = '60 min/t';
   timerOperation = MathOperation.times;
   timerResultFormatted = secondRowFirstValueFormatted;
  }

  const firstRowResultValue = convertBetweenUnits(
   units[InfusionScreen.PerTime.Third],
   units[InfusionScreen.PerTime.First],
   values[InfusionScreen.PerTime.Third],
  );
  const firstRowResultValueFormatted = `${firstRowResultValue} ${firstRowUnitSplitted[0]}${MathOperation.division}${UnitType.Ml}`;

  const secondRowResultValue = secondRowFirstValue / firstRowResultValue;
  const secondRowResultValueFormatted = `${secondRowResultValue} ${UnitType.Ml}${MathOperation.division}${UnitType.Timer}`;

  const thirdRowResultValue = secondRowResultValue / 60;
  const thirdRowResultValueFormatted = `${roundNumber(thirdRowResultValue)} ${
   UnitType.Ml
  }${MathOperation.division}${UnitType.Minutes}`;

  const fourthRowResultValue = thirdRowResultValue * 20;
  const fourthRowResultValueFormatted = `${roundNumber(fourthRowResultValue)} ${
   UnitType.Draper
  }${MathOperation.division}${UnitType.Minutes}`;

  const fifthRowResultValue = fourthRowResultValue / 60;
  const fifthRowResultValueFormatted = `${roundNumber(fifthRowResultValue)} ${
   UnitType.Draper
  }${MathOperation.division}sek`;

  const newOperationsResult: ResultOperation[] = [
   {
    firstValue: description[InfusionScreen.PerTime.Third],
    resultValue: firstRowResultValueFormatted,
    hide: !checkboxValue,
   },
   {
    firstValue: `${description[InfusionScreen.PerTime.First]}/kg`,
    operation: MathOperation.times,
    secondValue: description[InfusionScreen.PerTime.Second],
    resultValue: alternativeSecondRowFirstValueFormatted,
    hide: hideKg || !checkboxValue,
   },
   {
    firstValue: alternativeSecondRowFirstValueFormatted,
    operation: timerOperation,
    secondValue: timerSecondValueFormatted,
    resultValue: timerResultFormatted,
   },
   {
    firstValue: secondRowFirstValueFormatted,
    operation: MathOperation.division,
    secondValue: firstRowResultValueFormatted,
    resultValue: secondRowResultValueFormatted,
    highlighted: true,
   },
   {
    firstValue: secondRowResultValueFormatted,
    operation: MathOperation.division,
    secondValue: '60 min/t',
    resultValue: thirdRowResultValueFormatted,
    highlighted: true,
   },
   {
    firstValue: thirdRowResultValueFormatted,
    operation: MathOperation.times,
    secondValue: '20 draper/ml',
    resultValue: fourthRowResultValueFormatted,
    highlighted: true,
   },
   {
    firstValue: fourthRowResultValueFormatted,
    operation: MathOperation.division,
    secondValue: '60 s/min',
    resultValue: fifthRowResultValueFormatted,
    highlighted: true,
   },
  ];

  const finalResult = [...infusionPerTimeFinalResult];
  finalResult[0].calculatorValue = {
   ...finalResult[0].calculatorValue,
   value: secondRowResultValueFormatted,
  };
  finalResult[1].calculatorValue = {
   ...finalResult[1].calculatorValue,
   value: thirdRowResultValueFormatted,
  };
  finalResult[2].calculatorValue = {
   ...finalResult[2].calculatorValue,
   value: fourthRowResultValueFormatted,
  };
  finalResult[3].calculatorValue = {
   ...finalResult[3].calculatorValue,
   value: fifthRowResultValueFormatted,
  };

  setInfusionPerTimeFinalResult(finalResult);
  setInfusionPerTimeOperationsResult(newOperationsResult);
 };

 useEffect(() => {
  setResultArray(infusionPerTimeFinalResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionPerTimeFinalResult]);

 useEffect(() => {
  calculateResult();
  setResultRows(infusionPerTimeResultRows);
  saveHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionPerTimeResultRows]);

 useEffect(() => {
  setResultOperations(infusionPerTimeOperationsResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [infusionPerTimeOperationsResult]);

 useEffect(() => {
  setInfusionPerTimeResultRows(
   infusionPerTimeResultRows?.map((input, index) =>
    index > currentScreen ? { ...input, calculatorValue: undefined } : input,
   ),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [currentScreen]);

 useEffect(() => {
  setResultsCalculationHistory(calculationHistory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [calculationHistory]);

 return (
  <InfusionPerTimeContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    currentScreen,
    setCurrentScreen,
    homeRedirect,
    firstScreenRedirect,
    secondScreenRedirect,
    thirdScreenRedirect,
    resultsRedirect,
    infusionPerTimeResultRows,
    setInfusionPerTimeResultRows,
    onSelectChange,
    onValueChange,
    getValueInitialState,
    getSelectInitialState,
    selectedUnit,
    setSelectedUnit,
    calculationHistory,
    setCalculationHistory,
    loadHistory,
   }}
  >
   {children}
  </InfusionPerTimeContext.Provider>
 );
}
