import React, { useContext, useEffect, useState } from 'react';

import {
 GramUnitsPerMl,
 IEUnitsPerMl,
 MolUnitsPerMl,
 UnitType,
} from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { InjectionContext } from '../../../../contexts/InjectionProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';

import { getUnitTypeByString } from '../../../../utils';

function LiquidMiksturThirdScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setBackButtonAction,
  setNextButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { setQuestion, setUnitTypes, checkboxValue, setHasCheckbox } =
  useContext(CalculatorContext);

 const {
  firstScreenRedirect,
  secondScreenRedirect,
  resultsRedirect,
  onValueChange,
  onSelectChange,
  getSelectInitialState,
  getValueInitialState,
  selectedUnit,
 } = useContext(InjectionContext);

 const gramOptions = [
  UnitType.MicrogramPerMl,
  UnitType.MiligramPerMl,
  UnitType.GramPerMl,
 ];

 const molOptions = [
  UnitType.MicromolPerMl,
  UnitType.MilimolPerMl,
  UnitType.MolPerMl,
 ];

 const [localUnitTypes, setLocalUnitTypes] = useState(GramUnitsPerMl);

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
  };
  const setNextButtonProps = () => {
   setNextButtonText('Regn ut');
   setNextButtonAction(resultsRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };
 const setCalculatorProps = () => {
  setQuestion('Hvilken styrke har legemiddelet pr ml?');
  setUnitTypes(localUnitTypes);
  setHasCheckbox(false);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  if (checkboxValue) {
   setBackButtonAction(secondScreenRedirect);
  } else {
   setBackButtonAction(firstScreenRedirect);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [checkboxValue]);

 useEffect(() => {
  if (gramOptions.includes(getUnitTypeByString(selectedUnit))) {
   setLocalUnitTypes(
    GramUnitsPerMl.filter(
     unit =>
      ![UnitType.Kilogram, UnitType.KilogramPerMl].includes(unit.description),
    ),
   );
  } else if (molOptions.includes(getUnitTypeByString(selectedUnit))) {
   setLocalUnitTypes(MolUnitsPerMl);
  } else {
   setLocalUnitTypes(IEUnitsPerMl);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  setUnitTypes(localUnitTypes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [localUnitTypes]);

 return (
  <CalculatorComponent
   onValueChange={(value: string) => onValueChange(value)}
   onSelectChange={onSelectChange}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default LiquidMiksturThirdScreen;
