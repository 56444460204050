import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { InfusionScreen } from '../../../types';

import { CalculatorContext } from '../../../contexts/CalculatorProvider';
import { InfusionAddedDrugContext } from '../../../contexts/InfusionProvider/InfusionAddedDrugProvider';

import InfusionAddedDrugPageFirstScreen from './InfusionAddedDrugPageFirstScreen';
import InfusionAddedDrugPageSecondScreen from './InfusionAddedDrugPageSecondScreen';
import InfusionAddedDrugPageThirdScreen from './InfusionAddedDrugPageThirdScreen';
import InfusionAddedDrugPageFourthScreen from './InfusionAddedDrugPageFourthScreen';
import InfusionAddedDrugPageFifthScreen from './InfusionAddedDrugPageFifthScreen';
import InfusionAddedDrugPageSixthScreen from './InfusionAddedDrugPageSixthScreen';
import InfusionAddedDrugPageResultScreen from './InfusionAddedDrugPageResultScreen';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';

function DilutionAdditionVolumePage(): React.ReactElement {
 const { currentScreen, setCurrentScreen, loadHistory, homeRedirect } =
  useContext(InfusionAddedDrugContext);
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setHomeButtonAction } = useContext(CalculatorNavigationContext);
 const { state } = useLocation();

 useEffect(() => {
  if (currentScreen && currentScreen < InfusionScreen.AddedDrug.First) {
   setCurrentScreen(InfusionScreen.AddedDrug.First);
  }
  setCheckboxValue(false);
  if (state && state.data) {
   loadHistory(state.data);
  }
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === InfusionScreen.AddedDrug.First && (
    <InfusionAddedDrugPageFirstScreen />
   )}
   {currentScreen === InfusionScreen.AddedDrug.Second && (
    <InfusionAddedDrugPageSecondScreen />
   )}
   {currentScreen === InfusionScreen.AddedDrug.Third && (
    <InfusionAddedDrugPageThirdScreen />
   )}
   {currentScreen === InfusionScreen.AddedDrug.Fourth && (
    <InfusionAddedDrugPageFourthScreen />
   )}
   {currentScreen === InfusionScreen.AddedDrug.Fifth && (
    <InfusionAddedDrugPageFifthScreen />
   )}
   {currentScreen === InfusionScreen.AddedDrug.Sixth && (
    <InfusionAddedDrugPageSixthScreen />
   )}
   {currentScreen === InfusionScreen.AddedDrug.Result && (
    <InfusionAddedDrugPageResultScreen />
   )}
  </>
 );
}

export default DilutionAdditionVolumePage;
