import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CalculatorContext } from '../../../contexts/CalculatorProvider';
import { InfusionPerTimeContext } from '../../../contexts/InfusionProvider/InfusionPerTimeProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';
import { InfusionScreen, UnitType } from '../../../types';

import InfusionPerTimeFirstScreen from './InfusionPerTimeFirstScreen';
import InfusionPerTimeSecondScreen from './InfusionPerTimeSecondScreen';
import InfusionPerTimeThirdScreen from './InfusionPerTimeThirdScreen';
import InfusionPerTimeResultScreen from './InfusionPerTimeResultScreen';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';

function InfusionPerTime(): React.ReactElement {
 const { currentScreen, setCurrentScreen, loadHistory, homeRedirect } =
  useContext(InfusionPerTimeContext);
 const { setCheckboxValue, setShowInfo } = useContext(CalculatorContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();
 const { setHomeButtonAction } = useContext(CalculatorNavigationContext);

 useEffect(() => {
  if (currentScreen && currentScreen < InfusionScreen.PerTime.First) {
   setCurrentScreen(InfusionScreen.PerTime.First);
  }
  setCheckboxValue(false);
  setFinalResultUnit(UnitType.Tablett);
  setShowInfo(false);
  if (state && state.data) {
   loadHistory(state.data);
  }
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === InfusionScreen.PerTime.First && (
    <InfusionPerTimeFirstScreen />
   )}
   {currentScreen === InfusionScreen.PerTime.Second && (
    <InfusionPerTimeSecondScreen />
   )}
   {currentScreen === InfusionScreen.PerTime.Third && (
    <InfusionPerTimeThirdScreen />
   )}
   {currentScreen === InfusionScreen.PerTime.Result && (
    <InfusionPerTimeResultScreen />
   )}
  </>
 );
}

export default InfusionPerTime;
