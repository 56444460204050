import React, { useContext, useEffect } from 'react';

import { Flex, HStack, VStack, Text, Image } from '@chakra-ui/react';

import Question from '../../../../components/QuestionComponent';
import CalculatorNavigationComponent from '../../../../components/CalculatorComponent/CalculatorNavigationComponent';

import iconInfusionBottleFull from '../../../../assets/icons/icon-infusion-bottle-full.svg';
import iconInfusionBottleHalf from '../../../../assets/icons/icon-infusion-bottle-half.svg';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { InfusionScreen } from '../../../../types';
import { InfusionAddedDrugContext } from '../../../../contexts/InfusionProvider/InfusionAddedDrugProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';
import Header from '../../../../components/HeaderComponent';

function InfusionAddedDrugPageThirdScreen(): React.ReactElement {
 const { setBackButtonAction } = useContext(CalculatorNavigationContext);
 const { setCurrentScreen, secondScreenRedirect, firstScreenRedirect } =
  useContext(InfusionAddedDrugContext);
 const { checkboxValue, setPageTitle } = useContext(CalculatorContext);

 const handleClick = (screenNumber: number) => {
  if (screenNumber === InfusionScreen.AddedDrug.Fourth) {
   setPageTitle('Infusjon - Del av enhet');
  } else if (screenNumber === InfusionScreen.AddedDrug.Fifth) {
   setPageTitle('Infusjon - Hel enhet');
  }
  setCurrentScreen(screenNumber);
 };

 useEffect(() => {
  if (checkboxValue) {
   setBackButtonAction(secondScreenRedirect);
  } else {
   setBackButtonAction(firstScreenRedirect);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [checkboxValue]);

 return (
  <Flex flexDirection="column" w="100%">
   <CalculatorNavigationComponent hasNextButton={false} />
   <Flex w="100%" py="30px" px="30px">
    <Header />
   </Flex>
   <Flex flexDirection="column" px="30px" w="100%" h="100%">
    <Question
     title="Infusjon - Tilsatt legemiddel"
     question="Hva skal tilsettes?"
    />
    <VStack mt="auto" bg="gray.400" mx="-30px" px="40px">
     <HStack
      w="100%"
      h="130px"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="black.500"
      spacing="30px"
     >
      <Image w="32px" src={iconInfusionBottleFull} />
      <Text
       fontWeight="600"
       fontSize="24"
       onClick={() => handleClick(InfusionScreen.AddedDrug.Fifth)}
       w="100%"
      >
       Tilsett hele ampullen eller hetteglasset
      </Text>
     </HStack>
     <HStack
      w="100%"
      h="130px"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="black.500"
      spacing="30px"
     >
      <Image w="32px" src={iconInfusionBottleHalf} />
      <Text
       fontWeight="600"
       fontSize="24"
       onClick={() => handleClick(InfusionScreen.AddedDrug.Fourth)}
       w="100%"
      >
       Tilsett deler av ampullen eller hetteglasset
      </Text>
     </HStack>
    </VStack>
   </Flex>
  </Flex>
 );
}

export default InfusionAddedDrugPageThirdScreen;
