import React, { createContext, useEffect, useState, useRef } from 'react';

import { AppContextType } from '../types/contexts';
import { GET_APP_INFO_ENDPOINT } from '../constants';
import { BooleanType } from '../types';

export const AppContext = createContext<AppContextType>({
 setShowInfoAlert: () => null,
 showInfoAlert: true,
 infoContent: ''
});

interface AppProviderProps {
 children: React.ReactElement;
}

function hashify(str: string): number {
  let hash = 0;
  if (str.length == 0) return hash;
  for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
  }
  return hash;
}

export function AppProvider({ children }: AppProviderProps): React.ReactElement {
 const [showInfoAlert, setShowInfoAlert] = useState(true);
//  const [infoAlertHash, setInfoAlertHash] = useState(0);
 const [infoContent, setInfoContent] = useState('')
 const [isPollingEnabled, setIsPollingEnabled] = useState(true)

 useEffect(() => {
  const storedShowInfoAlert = localStorage.getItem('showInfoAlert');
  if (storedShowInfoAlert === null || storedShowInfoAlert === BooleanType.true) {
   setShowInfoAlert(true);
  } else {
   setShowInfoAlert(false);
  }
 }, [])

  const timerIdRef = useRef<ReturnType<typeof setInterval> | undefined | null>(null) || {}

  useEffect(() => {
    const pollingCallback = () => {
      if (GET_APP_INFO_ENDPOINT) {
        fetch(GET_APP_INFO_ENDPOINT, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json())
        .then(data => {
          const text = data.description
          // console.log('polling')
          if(text !== infoContent) {
            // console.log('updating')
            const storedInfoContentHash = JSON.parse(localStorage.getItem('infoContentHash') || '0')
            const newContentHash = hashify(text)
            if(storedInfoContentHash !== newContentHash) {
              setShowInfoAlert(true)
              localStorage.setItem('infoContentHash', JSON.stringify(newContentHash))
            }

            const ret = setInfoContent(text)
            return ret
          }
          return undefined
        }).catch(() => {
          setIsPollingEnabled(false)
        })
      }
    }

    const startPolling = () => {
      // console.log('start polling')
      pollingCallback() // To immediately start fetching data

      timerIdRef.current = setInterval(pollingCallback, 10000)
    }

    const stopPolling = () => {
      // console.log('stop polling')
      timerIdRef?.current && clearInterval(timerIdRef.current)
    }

    if (isPollingEnabled) {
      startPolling()
    } else {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [infoContent])

 return (
  <AppContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    showInfoAlert,
    setShowInfoAlert,
    infoContent
   }}
  >
   {children}
  </AppContext.Provider>
 );
}
