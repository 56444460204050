import React, { useContext, useEffect, useState } from 'react';

import {
 Input,
 VStack,
 Text,
 Flex,
 Checkbox,
 CheckboxGroup,
} from '@chakra-ui/react';

import { CalculatorContext } from '../../../contexts/CalculatorProvider';

import SelectUnitComponent from '../../SelectUnitComponent';

interface CalculatorInputsComponentProps {
 selectValue: string;
 setSelectValue: (value: string) => void;
 onHandleChangeInputValue: (value: string) => void;
 inputValue: string;
 selectPerTime?: boolean;
}

function CalculatorInputsComponent({
 onHandleChangeInputValue,
 selectValue,
 setSelectValue,
 inputValue,
 selectPerTime,
}: CalculatorInputsComponentProps): React.ReactElement {
 const {
  unitTypes,
  hasCheckbox,
  checkboxText,
  checkboxAction,
  checkboxValue,
  setCheckboxValue,
 } = useContext(CalculatorContext);

 const onHandleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) =>
  onHandleChangeInputValue(event.target.value);

 const [checkboxStringValue, setCheckboxStringValue] = useState<string>('');

 useEffect(() => {
  setCheckboxStringValue(checkboxValue ? 'checkbox' : '');
 }, [checkboxValue]);

 const onHandleChangeCheckbox = (
  event: React.ChangeEvent<HTMLInputElement>,
 ) => {
  setCheckboxValue(event.target.checked);
  if (checkboxAction) {
   checkboxAction();
  }
 };

 return (
  <VStack px="30px" width="100%" flex={1} mb="10px !important">
   <Flex width="100%">
    <Input
     bg="gray.500"
     type="text"
     h="68px"
     borderRadius={15}
     mr="10px"
     fontSize="42px"
     color="black.500"
     fontWeight="700"
     textAlign="right"
     placeholder="0"
     value={inputValue}
     _placeholder={{ color: '#4C4C4C' }}
     onChange={onHandleChangeLocal}
    />
    <SelectUnitComponent
     value={selectValue}
     unitTypes={unitTypes}
     onChange={setSelectValue}
     perTime={selectPerTime}
    />
   </Flex>
   {hasCheckbox && (
    <Flex w="100%" py="10px" textAlign="center" h="50%">
     <CheckboxGroup value={[checkboxStringValue]}>
      <Checkbox
       value="checkbox"
       size="lg"
       checked={checkboxValue}
       colorScheme="blue"
       iconColor="white"
       iconSize="20px"
       id="per-kg"
       onChange={onHandleChangeCheckbox}
      >
       <Text color="black.500" fontSize="16px">
        {checkboxText}
       </Text>
      </Checkbox>
     </CheckboxGroup>
    </Flex>
   )}
  </VStack>
 );
}

CalculatorInputsComponent.defaultProps = {
 selectPerTime: false,
};

export default CalculatorInputsComponent;
