// Fortynning - Tilsetningsvolum

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
 AppRoutes,
 CalculationHistory,
 DilutionRoutes,
 DilutionScreen,
 MathOperation,
 ResultOperation,
 ResultRow,
 UnitType,
} from '../../../types';

import { DilutionStrengthDilutedContextType } from '../../../types/contexts';

import {
 getUnitTypeByString,
 getUnitOfMeasurementByUnitType,
 roundNumber,
 getValuesFromResultRows,
 getDescriptionsFromResultRows,
} from '../../../utils';

import { ResultsContext } from '../../ResultsProvider';

export const DilutionStrengthDilutedContext =
 createContext<DilutionStrengthDilutedContextType>({
  setCurrentScreen: () => null,
  setDilutionStrengthDilutedResultRows: () => null,
  dilutionStrengthDilutedResultRows: [],
  onSelectChange: () => null,
  onValueChange: () => null,
  getValueInitialState: () => '',
  getSelectInitialState: () => '',
  selectedUnit: UnitType.Gram,
  setSelectedUnit: () => null,
  loadHistory: () => null,
  setCalculationHistory: () => null,
 });

interface DilutionStrengthDilutedProviderProps {
 children: React.ReactElement;
}

export function DilutionStrengthDilutedProvider({
 children,
}: DilutionStrengthDilutedProviderProps): React.ReactElement {
 const navigation = useNavigate();

 const [currentScreen, setCurrentScreen] = useState(-1);

 const [
  dilutionStrengthDilutedResultRows,
  setDilutionStrengthDilutedResultRows,
 ] = useState<Array<ResultRow>>([
  { description: 'Styrke, konsentrat' },
  { description: 'Volum konsentrat' },
  { description: 'Volum tilsetning' },
 ]);

 const [
  dilutionStrengthDilutedOperationsResult,
  setDilutionStrengthDilutedOperationsResult,
 ] = useState<Array<ResultOperation>>([
  { firstValue: '', operation: MathOperation.times, secondValue: '' },
  { firstValue: '', operation: MathOperation.plus, secondValue: '' },
  { firstValue: '', operation: MathOperation.division, secondValue: '' },
  { firstValue: '', operation: MathOperation.minus, secondValue: '' },
 ]);

 const [
  dilutionStrengthDilutedFinalResult,
  setDilutionStrengthDilutedFinalResult,
 ] = useState(0);

 const [selectedUnit, setSelectedUnit] = useState<string>('');

 const {
  setResultRows,
  setFinalResultValue,
  setResultOperations,
  setFinalResultUnit,
  setResultsCalculationHistory,
 } = useContext(ResultsContext);

 const [calculationHistory, setCalculationHistory] =
  useState<CalculationHistory>({
   title: 'Styrke, fortynnet',
   redirectTo: DilutionRoutes.StrengthDiluted,
  });

 const saveHistory = () => {
  setCalculationHistory({
   ...calculationHistory,
   date: new Date(),
   data: {
    currentScreen: DilutionScreen.Result,
    resultRows: dilutionStrengthDilutedResultRows,
    operationResult: dilutionStrengthDilutedOperationsResult,
   },
  });
 };

 const loadHistory = (data: any) => {
  if (data.currentScreen) {
   setCurrentScreen(data.currentScreen);
  }
  if (data.resultRows) {
   setDilutionStrengthDilutedResultRows(data.resultRows);
  }
  if (data.operationResult) {
   setDilutionStrengthDilutedOperationsResult(data.operationResult);
  }
  if (data.index !== undefined) {
   setCalculationHistory({
    ...calculationHistory,
    index: data.index,
   });
  }
 };

 const homeRedirect = () => () => {
  setCalculationHistory({ ...calculationHistory, index: undefined });
  setDilutionStrengthDilutedResultRows([
   { description: 'Styrke, konsentrat' },
   { description: 'Volum konsentrat' },
   { description: 'Volum tilsetning' },
  ]);
  setCurrentScreen(-1);
  navigation(AppRoutes.Home);
 };

 const firstScreenRedirect = () => () => setCurrentScreen(DilutionScreen.First);
 const secondScreenRedirect = () => () =>
  setCurrentScreen(DilutionScreen.Second);
 const thirdScreenRedirect = () => () => setCurrentScreen(DilutionScreen.Third);
 const resultsRedirect = () => () => setCurrentScreen(DilutionScreen.Result);

 const onSelectChange = (string: string) => {
  const unitType = getUnitTypeByString(string);
  const unitOfMeasurement = getUnitOfMeasurementByUnitType(unitType);
  const newDilutionStrengthDilutedResultRows = [
   ...dilutionStrengthDilutedResultRows,
  ].map((input, index) =>
   index === currentScreen
    ? {
       ...input,
       calculatorValue: { ...input.calculatorValue, unit: unitOfMeasurement },
      }
    : input,
  );
  setDilutionStrengthDilutedResultRows(newDilutionStrengthDilutedResultRows);
 };

 const onValueChange = (value: string) => {
  const newDilutionStrengthDilutedResultRows = [
   ...dilutionStrengthDilutedResultRows,
  ].map((input, index) =>
   index === currentScreen
    ? { ...input, calculatorValue: { ...input.calculatorValue, value } }
    : input,
  );
  setDilutionStrengthDilutedResultRows(newDilutionStrengthDilutedResultRows);
 };

 const getValueInitialState = () => {
  const value = dilutionStrengthDilutedResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.value;
  return value ?? '';
 };

 const getSelectInitialState = () => {
  const value = dilutionStrengthDilutedResultRows.find(
   (input, index) => index === currentScreen,
  )?.calculatorValue?.unit?.description;
  return value ?? '';
 };

 const getUnitWithoutMl = (description: string) => {
  const firstSplit = description.split(' ');
  if (firstSplit[1]) {
   return firstSplit[1].split(MathOperation.division)[0];
  }
  return '';
 };

 const getUnitWithMl = (description: string) => {
  return description.split(' ')[1];
 };

 const calculateResult = () => {
  const values = getValuesFromResultRows(dilutionStrengthDilutedResultRows);
  const description = getDescriptionsFromResultRows(
   dilutionStrengthDilutedResultRows,
  );
  const newOperationsResult = [...dilutionStrengthDilutedOperationsResult];

  if (
   !values[DilutionScreen.First] ||
   !values[DilutionScreen.Second] ||
   !values[DilutionScreen.Third]
  ) {
   return;
  }

  const firstPartialResult =
   values[DilutionScreen.First] * values[DilutionScreen.Second];
  const firstFormattedPartialResult = `${roundNumber(
   firstPartialResult,
  )} ${getUnitWithoutMl(description[DilutionScreen.First])}`;

  const secondPartialResult =
   values[DilutionScreen.Second] + values[DilutionScreen.Third];
  const secondFormattedPartialResult = `${roundNumber(secondPartialResult)} ${
   UnitType.Ml
  }`;

  const finalResult = roundNumber(firstPartialResult / secondPartialResult, 2);
  const finalResultUnit = getUnitWithMl(description[DilutionScreen.First]);
  const finalResultFormatted = `${finalResult} ${finalResultUnit}`;

  // First operation row
  newOperationsResult[DilutionScreen.First].firstValue =
   description[DilutionScreen.First];
  newOperationsResult[DilutionScreen.First].secondValue =
   description[DilutionScreen.Second];
  newOperationsResult[DilutionScreen.First].resultValue =
   firstFormattedPartialResult;

  // Second operation row
  newOperationsResult[DilutionScreen.Second].firstValue =
   description[DilutionScreen.Second];
  newOperationsResult[DilutionScreen.Second].secondValue =
   description[DilutionScreen.Third];
  newOperationsResult[DilutionScreen.Second].resultValue =
   secondFormattedPartialResult;

  // Third operation row
  newOperationsResult[DilutionScreen.Third].firstValue =
   firstFormattedPartialResult;
  newOperationsResult[DilutionScreen.Third].secondValue =
   secondFormattedPartialResult;
  newOperationsResult[DilutionScreen.Third].resultValue = finalResultFormatted;

  setDilutionStrengthDilutedFinalResult(finalResult);
  setDilutionStrengthDilutedOperationsResult(newOperationsResult);
  setFinalResultUnit(finalResultUnit);
 };

 useEffect(() => {
  setFinalResultValue(dilutionStrengthDilutedFinalResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dilutionStrengthDilutedFinalResult]);

 useEffect(() => {
  calculateResult();
  setResultRows(dilutionStrengthDilutedResultRows);
  saveHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dilutionStrengthDilutedResultRows]);

 useEffect(() => {
  setResultOperations(dilutionStrengthDilutedOperationsResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dilutionStrengthDilutedOperationsResult]);

 useEffect(() => {
  setDilutionStrengthDilutedResultRows(
   dilutionStrengthDilutedResultRows?.map((input, index) =>
    index > currentScreen ? { ...input, calculatorValue: undefined } : input,
   ),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [currentScreen]);

 useEffect(() => {
  setResultsCalculationHistory(calculationHistory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [calculationHistory]);

 return (
  <DilutionStrengthDilutedContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    currentScreen,
    setCurrentScreen,
    homeRedirect,
    firstScreenRedirect,
    secondScreenRedirect,
    thirdScreenRedirect,
    resultsRedirect,
    dilutionStrengthDilutedResultRows,
    setDilutionStrengthDilutedResultRows,
    onSelectChange,
    onValueChange,
    getValueInitialState,
    getSelectInitialState,
    selectedUnit,
    setSelectedUnit,
    calculationHistory,
    setCalculationHistory,
    loadHistory,
   }}
  >
   {children}
  </DilutionStrengthDilutedContext.Provider>
 );
}
