import React, { createContext, useState } from 'react';

import { CalculatorNavigationContextType } from '../types/contexts';

export const CalculatorNavigationContext =
 createContext<CalculatorNavigationContextType>({
  setBackButtonAction: () => null,
  setNextButtonDisabled: () => null,
  setBackButtonText: () => null,
  setNextButtonText: () => null,
  setNextButtonAction: () => null,
  setHomeButtonAction: () => null,
 });

interface CalculatorNavigationProviderProps {
 children: React.ReactElement;
}

export function CalculatorNavigationProvider({
 children,
}: CalculatorNavigationProviderProps): React.ReactElement {
 const [backButtonText, setBackButtonText] = useState('');
 const [backButtonAction, setBackButtonAction] = useState(null);

 const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
 const [nextButtonText, setNextButtonText] = useState('');
 const [nextButtonAction, setNextButtonAction] = useState(null);

 const [homeButtonAction, setHomeButtonAction] = useState(null);

 return (
  <CalculatorNavigationContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    backButtonAction,
    setBackButtonAction,
    nextButtonDisabled,
    setNextButtonDisabled,
    backButtonText,
    setBackButtonText,
    nextButtonText,
    setNextButtonText,
    nextButtonAction,
    setNextButtonAction,
    homeButtonAction,
    setHomeButtonAction,
   }}
  >
   {children}
  </CalculatorNavigationContext.Provider>
 );
}
