import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CalculatorContext } from '../../../contexts/CalculatorProvider';
import { InjectionContext } from '../../../contexts/InjectionProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';
import { InjectionScreen, LiquidRoutes } from '../../../types';

import LiquidMiksturFirstScreen from './LiquidMiksturFirstScreen';
import LiquidMiksturResultScreen from './LiquidMiksturResultScreen';
import LiquidMiksturSecondScreen from './LiquidMiksturSecondScreen';
import LiquidMiksturThirdScreen from './LiquidMiksturThirdScreen';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';

function LiquidMiksturPage(): React.ReactElement {
 const {
  currentScreen,
  setCurrentScreen,
  setCalculationHistory,
  loadHistory,
  homeRedirect,
 } = useContext(InjectionContext);
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setHomeButtonAction } = useContext(CalculatorNavigationContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();

 useEffect(() => {
  if (currentScreen && currentScreen < InjectionScreen.First) {
   setCurrentScreen(InjectionScreen.First);
  }
  setCheckboxValue(false);
  setFinalResultUnit('ml');
  setHomeButtonAction(homeRedirect);
  setCalculationHistory({
   title: 'Flytende - Mikstur',
   redirectTo: LiquidRoutes.Mikstur,
  });
  if (state && state.data) {
   loadHistory(state.data);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === InjectionScreen.First && <LiquidMiksturFirstScreen />}
   {currentScreen === InjectionScreen.Second && <LiquidMiksturSecondScreen />}
   {currentScreen === InjectionScreen.Third && <LiquidMiksturThirdScreen />}
   {currentScreen === InjectionScreen.Result && <LiquidMiksturResultScreen />}
  </>
 );
}

export default LiquidMiksturPage;
