import React, { useContext, useEffect } from 'react';

import { MlUnits } from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';
import { InfusionAddedDrugContext } from '../../../../contexts/InfusionProvider/InfusionAddedDrugProvider';

function InfusionAddedDrugPageFifthScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setNextButtonAction,
  setBackButtonAction,
 } = useContext(CalculatorNavigationContext);

 const {
  onSelectChange,
  onValueChange,
  getValueInitialState,
  getSelectInitialState,
  thirdScreenRedirect,
  fourthScreenRedirect,
  sixthScreenRedirect,
  hasFourthScreen,
  infusionAddedDrugInfoText,
 } = useContext(InfusionAddedDrugContext);

 const { setHasCheckbox, setQuestion, setUnitTypes, setShowInfo, setInfoText } =
  useContext(CalculatorContext);

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   if (hasFourthScreen()) {
    setBackButtonAction(fourthScreenRedirect);
   } else {
    setBackButtonAction(thirdScreenRedirect);
   }
  };
  const setNextButtonProps = () => {
   setNextButtonText('Neste');
   setNextButtonAction(sixthScreenRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };

 const setCalculatorProps = () => {
  setHasCheckbox(false);
  setQuestion('Hva er infusjonsposens totale volum?');
  setUnitTypes(MlUnits);
  setShowInfo(true);
  setInfoText(
   'Husk a inkludere legemidlet i totalvolumet. Følg lokal prosedyre for inkludering av skyllevolum.',
  );
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  setInfoText(infusionAddedDrugInfoText || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <CalculatorComponent
   onValueChange={onValueChange}
   onSelectChange={value => {
    onSelectChange(value);
   }}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default InfusionAddedDrugPageFifthScreen;
