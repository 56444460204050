import DilutionPage from './DilutionPage';
import HomePage from './HomePage';
import InfusionPage from './InfusionPage';
import InjectionPage from './InjectionPage';
import LiquidPage from './LiquidPage';
import PillsPage from './PillsPage';
import NotFoundPage from './NotFoundPage';

export const Pages = {
 Dilution: DilutionPage,
 Home: HomePage,
 Infusion: InfusionPage,
 Injection: InjectionPage,
 Liquid: LiquidPage,
 Pills: PillsPage,
 NotFound: NotFoundPage,
};
