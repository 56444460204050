import React, { useState } from 'react';

import { Button, Text, Flex, Grid } from '@chakra-ui/react';

import Modal from 'react-modal';

import { GrClose } from 'react-icons/gr';

import { UnitOfMeasurement } from '../../types';

interface SelectUnitComponentProps {
 unitTypes: UnitOfMeasurement[];
 value?: string;
 onChange: (value: string) => void;
 perTime?: boolean;
}

function SelectUnitComponent({
 unitTypes,
 value,
 onChange,
 perTime,
}: SelectUnitComponentProps): React.ReactElement {
 const [isOpen, setIsOpen] = useState(false);
 const [isOpenPerTime, setIsOpenPerTime] = useState(false);
 const perTimeUnitTypes = ['per time', 'per min'];

 const open = () => {
  setIsOpen(true);
 };

 const close = () => {
  setIsOpen(false);
  setIsOpenPerTime(false);
 };

 const onClickOption = (optionValue: string) => {
  onChange(optionValue);
  if (perTime) {
   setIsOpen(false);
   setIsOpenPerTime(true);
  } else {
   close();
  }
 };

 const onClickOptionPerTime = (optionValue: string) => {
  onChange(`${value}/${optionValue === 'per time' ? 't' : 'min'}`);
  close();
 };

 return (
  <>
   <Button
    bg="red.500"
    color="white"
    h="68px"
    w="125px"
    borderRadius="15px"
    onClick={open}
   >
    {value || 'Velg enhet'}
   </Button>
   <Modal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={close}
    style={{
     overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 2,
     },
     content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '500px',
     },
    }}
   >
    <Flex justifyContent="space-between" mb="30px">
     <Text fontSize="28px" />
     <Button
      onClick={close}
      p={0}
      bg="white"
      h="auto"
      minWidth={0}
      _hover={{ backgroundColor: 'white' }}
     >
      <GrClose size={32} />
     </Button>
    </Flex>
    <Grid templateColumns="repeat(3, 1fr)" gap={15}>
     <Button
      value=""
      h="70px"
      onClick={() => onClickOption('')}
      bg={value !== '' ? 'gray.400' : 'blue.200'}
     >
      Velg enhet
     </Button>
     {unitTypes.map(unit => (
      <Button
       key={unit.description}
       value={unit.description}
       bg={value !== unit.description ? 'gray.400' : 'blue.100'}
       h="70px"
       onClick={() => onClickOption(unit.description)}
      >
       {unit.description}
      </Button>
     ))}
    </Grid>
   </Modal>
   <Modal
    ariaHideApp={false}
    isOpen={isOpenPerTime}
    // onRequestClose={close}
    style={{
     overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
     },
     content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '500px',
     },
    }}
   >
    <Grid templateColumns="repeat(2, 1fr)" gap={15}>
     {perTimeUnitTypes.map(unit => (
      <Button
       key={unit}
       value={unit}
       bg={value !== unit ? 'gray.400' : 'blue.100'}
       h="70px"
       onClick={() => onClickOptionPerTime(unit)}
      >
       {unit.replace('per', 'pr')}
      </Button>
     ))}
    </Grid>
   </Modal>
  </>
 );
}

SelectUnitComponent.defaultProps = {
 value: '',
 perTime: false,
};

export default SelectUnitComponent;
