import React, { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { DilutionScreen, UnitType } from '../../../types';

import { CalculatorContext } from '../../../contexts/CalculatorProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';
import { DilutionStrengthDilutedContext } from '../../../contexts/DilutionProvider/DilutionStrengthDilutedProvider';

import DilutionStrengthDilutedFirstScreen from './DilutionStrengthDilutedFirstScreen';
import DilutionStrengthDilutedSecondScreen from './DilutionStrengthDilutedSecondScreen';
import DilutionStrengthDilutedThirdScreen from './DilutionStrengthDilutedThirdScreen';
import DilutionStrengthDilutedResultScreen from './DilutionStrengthDilutedResultScreen';

function DilutionStrengthDilutedPage(): React.ReactElement {
 const { currentScreen, setCurrentScreen, loadHistory } = useContext(
  DilutionStrengthDilutedContext,
 );
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();

 useEffect(() => {
  if (currentScreen && currentScreen < DilutionScreen.First) {
   setCurrentScreen(DilutionScreen.First);
  }
  setCheckboxValue(false);
  setFinalResultUnit(UnitType.Ml);
  if (state && state.data) {
   loadHistory(state.data);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === DilutionScreen.First && (
    <DilutionStrengthDilutedFirstScreen />
   )}
   {currentScreen === DilutionScreen.Second && (
    <DilutionStrengthDilutedSecondScreen />
   )}
   {currentScreen === DilutionScreen.Third && (
    <DilutionStrengthDilutedThirdScreen />
   )}
   {currentScreen === DilutionScreen.Result && (
    <DilutionStrengthDilutedResultScreen />
   )}
  </>
 );
}

export default DilutionStrengthDilutedPage;
