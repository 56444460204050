import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CalculatorContext } from '../../contexts/CalculatorProvider';
import { InjectionContext } from '../../contexts/InjectionProvider';
import { ResultsContext } from '../../contexts/ResultsProvider';
import { AppRoutes, InjectionScreen, UnitType } from '../../types';

import InjectionFirstScreen from './InjectionFirstScreen';
import InjectionResultScreen from './InjectionResultScreen';
import InjectionSecondScreen from './InjectionSecondScreen';
import InjectionThirdScreen from './InjectionThirdScreen';
import { CalculatorNavigationContext } from '../../contexts/CalculatorNavigationProvider';

function Injection(): React.ReactElement {
 const {
  currentScreen,
  setCurrentScreen,
  loadHistory,
  setCalculationHistory,
  homeRedirect,
 } = useContext(InjectionContext);
 const { setHomeButtonAction } = useContext(CalculatorNavigationContext);
 const { setCheckboxValue } = useContext(CalculatorContext);
 const { setFinalResultUnit } = useContext(ResultsContext);
 const { state } = useLocation();

 useEffect(() => {
  if (currentScreen && currentScreen < InjectionScreen.First) {
   setCurrentScreen(InjectionScreen.First);
  }
  setCheckboxValue(false);
  setFinalResultUnit(UnitType.Ml);
  setCalculationHistory({
   title: 'Injeksjon',
   redirectTo: AppRoutes.Injection,
  });
  if (state && state.data) {
   loadHistory(state.data);
  }
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <>
   {currentScreen === InjectionScreen.First && <InjectionFirstScreen />}
   {currentScreen === InjectionScreen.Second && <InjectionSecondScreen />}
   {currentScreen === InjectionScreen.Third && <InjectionThirdScreen />}
   {currentScreen === InjectionScreen.Result && <InjectionResultScreen />}
  </>
 );
}

export default Injection;
