import React, { useContext, useEffect } from 'react';

import { MlUnits } from '../../../../types';

import CalculatorComponent from '../../../../components/CalculatorComponent';

import { CalculatorNavigationContext } from '../../../../contexts/CalculatorNavigationProvider';
import { DilutionStrengthDilutedContext } from '../../../../contexts/DilutionProvider/DilutionStrengthDilutedProvider';
import { CalculatorContext } from '../../../../contexts/CalculatorProvider';

function DilutionStrengthDilutedThirdScreen(): React.ReactElement {
 const {
  setBackButtonText,
  setNextButtonText,
  setBackButtonAction,
  setNextButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { setQuestion, setUnitTypes, setHasCheckbox } =
  useContext(CalculatorContext);

 const {
  secondScreenRedirect,
  resultsRedirect,
  onValueChange,
  onSelectChange,
  getSelectInitialState,
  getValueInitialState,
 } = useContext(DilutionStrengthDilutedContext);

 const setNavigationProps = () => {
  const setBackButtonProps = () => {
   setBackButtonText('Forrige');
   setBackButtonAction(secondScreenRedirect);
  };
  const setNextButtonProps = () => {
   setNextButtonText('Neste');
   setNextButtonAction(resultsRedirect);
  };
  setBackButtonProps();
  setNextButtonProps();
 };
 const setCalculatorProps = () => {
  setQuestion('Hva er tilsetningsvolumet?');
  setUnitTypes(MlUnits);
  setHasCheckbox(false);
 };

 useEffect(() => {
  setNavigationProps();
  setCalculatorProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <CalculatorComponent
   onValueChange={(value: string) => onValueChange(value)}
   onSelectChange={onSelectChange}
   valueInitialState={getValueInitialState()}
   selectInitialState={getSelectInitialState()}
  />
 );
}

export default DilutionStrengthDilutedThirdScreen;
