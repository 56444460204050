import {
 AppRoutes,
 CalculationHistory,
 CalculatorOption,
 DraperUnits,
 GramUnits,
 GramUnitsPerMinute,
 GramUnitsPerMl,
 GramUnitsPerTime,
 IEUnits,
 IEUnitsPerMinute,
 IEUnitsPerMl,
 IEUnitsPerTime,
 MlUnits,
 MolUnits,
 MolUnitsPerMinute,
 MolUnitsPerMl,
 MolUnitsPerTime,
 ResultRow,
 UnitOfMeasurement,
 UnitType,
} from '../types'

import imagePills from '../assets/icons/icon-pills.svg'
import imageLiquid from '../assets/icons/icon-liquid.svg'
import imageInjection from '../assets/icons/icon-injection.svg'
import imageInfusion from '../assets/icons/icon-infusion.svg'
import imageDilution from '../assets/icons/icon-dilution-strength-diluted.svg'

export function capitalizeFirstLetter(string: string): string {
 return string.charAt(0).toUpperCase() + string.slice(1)
}

export function removeFirstLetter(string: string): string {
 return string.substring(1)
}

export function removeLastLetter(string: string): string {
 return string.slice(0, -1)
}

export function getLastLetter(string: string): string {
 return string.substr(-1)
}

export function isNumber(string: string): boolean {
 if (!string) {
  return false
 }
 if (string.includes('.')) {
  return false
 }
 return !Number.isNaN(+string.replace(',', '.'))
}

export function charOccurrencesInString(char: string, string: string): number {
 return string.split(char).length - 1
}

export function getUnitTypeByString(string: string): UnitType {
 const unitType = Object.values(UnitType).find(unit => {
  return unit === string
 })
 return unitType || UnitType.Empty
}

export function getUnitOfMeasurementByUnitType(
 unitType: UnitType,
): UnitOfMeasurement | undefined {
 const units = [
  ...GramUnits,
  ...MolUnits,
  ...GramUnitsPerMl,
  ...GramUnitsPerTime,
  ...GramUnitsPerMinute,
  ...MolUnitsPerMl,
  ...MolUnitsPerTime,
  ...MolUnitsPerMinute,
  ...IEUnits,
  ...IEUnitsPerMl,
  ...IEUnitsPerMinute,
  ...IEUnitsPerTime,
  ...DraperUnits,
  ...MlUnits,
 ]
 const result = units.find(unit => unit.description === unitType)
 return result
}

export function getNumberFromString(string: string | undefined): number {
 if (!string) return 0
 return parseFloat(string.replace(/,/g, '.'))
}

export function getStringFromNumber(number: number): string {
 return `${number}`
}

export function roundNumber(number: number, decimalPlates = 6): number {
 return +number.toFixed(decimalPlates)
}

export function getIcons(): { [x: string]: string } {
 const icons = {
  [`${AppRoutes.Pills}`]: imagePills,
  [`${AppRoutes.Liquid}`]: imageLiquid,
  [`${AppRoutes.Injection}`]: imageInjection,
  [`${AppRoutes.Infusion}`]: imageInfusion,
  [`${AppRoutes.Dilution}`]: imageDilution,
 }
 return icons
}

export function getValuesPerWeightFromResultRows(
 resultRows: Array<ResultRow>,
): Array<number> {
 return resultRows.map(
  row =>
   getNumberFromString(row.calculatorValue?.value) *
   (row.calculatorValue?.unit?.weight || 1),
 )
}

export function getValuesFromResultRows(
 resultRows: Array<ResultRow>,
): Array<number> {
 return resultRows.map(row =>
  typeof row.calculatorValue?.value === 'string'
   ? getNumberFromString(row.calculatorValue?.value)
   : 1,
 )
}

export function getDescriptionsFromResultRows(
 resultRows: Array<ResultRow>,
): Array<string> {
 return resultRows.map(row => {
  if (!row.calculatorValue?.value) {
   return ''
  }
  return `${row.calculatorValue?.value || ''} ${
   row.calculatorValue?.unit?.description || ''
  }`
 })
}

export function getUnitsFromResultRows(
 resultRows: Array<ResultRow>,
): Array<UnitOfMeasurement | undefined> {
 return resultRows.map(row => row.calculatorValue?.unit)
}

export function convertUnit(
 value: number,
 unit1: UnitOfMeasurement | undefined,
 unit2: UnitOfMeasurement | undefined,
): string {
 if (!unit1 || !unit2) {
  return getStringFromNumber(value)
 }
 return `${getStringFromNumber(value)} ${
  unit1.weight < unit2.weight ? unit1.description : unit2.description
 }`
}

export function getOnlyNumbers(value: string): string {
 return value.replace(/\D/g, '')
}

export function getTimerValueFromString(value: string): number {
 const valueSplitted = value.split(CalculatorOption.Comma)
 valueSplitted[0] = valueSplitted[0].trim() === 't' ? '0t' : valueSplitted[0]
 const timerValue = parseInt(getOnlyNumbers(valueSplitted[0]), 10)
 const minutterValue = valueSplitted[1]
  ? parseInt(getOnlyNumbers(valueSplitted[1]), 10)
  : 0
 return timerValue * 60 + minutterValue
}

export function formatTimerString(value: string): string {
 const valueSplitted = value.replaceAll('\n', '').split(CalculatorOption.Comma)
 const hourValueString = valueSplitted[0].trim()
 const minuteValueString = valueSplitted[1]?.trim() || '0'
 const minuteValue = parseInt(getOnlyNumbers(minuteValueString), 10)
 if (hourValueString.replaceAll(' ', '') === '0t') {
  return `${minuteValue / 60} timer`
 }
 return value
}

export function saveNewHistory(
 history: CalculationHistory | undefined,
): undefined {
 if (history === undefined) return
 const storedHistory = localStorage.getItem('calculation-history')
 let historyArray: Array<CalculationHistory> = []

 if (storedHistory) {
  historyArray = JSON.parse(storedHistory)
 }

 if (history.index !== undefined) {
  historyArray[history.index] = history
 } else {
  if (historyArray.length > 2) {
   historyArray.pop()
  }
  historyArray.unshift(history)
 }

 localStorage.setItem('calculation-history', JSON.stringify(historyArray))
}

export function isValidInputValueTimer(
 inputValueTimer: Array<string>,
): boolean {
 if (!inputValueTimer) {
  return false
 }
 if (inputValueTimer.length !== 2) {
  return false
 }
 if (!inputValueTimer[0] && !inputValueTimer[1]) {
  return false
 }
 let timerValid = true
 let minutterValid = true
 if (isNumber(inputValueTimer[0])) {
  timerValid = parseInt(inputValueTimer[0], 10) <= 30
 }
 if (isNumber(inputValueTimer[1])) {
  minutterValid = parseInt(inputValueTimer[1], 10) < 60
 }
 if (!timerValid) {
  return false
 }
 if (!minutterValid) {
  return false
 }
 return true
}

export function getTabletterUnit(value: number): string {
 let unit = UnitType.Tablett.toString()
 if (value > 1) {
  unit += 'er'
 }
 return unit
}
